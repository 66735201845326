import React from 'react';
import './SettingsPage.scss';
import UserSettingsTab from './tabs/UserSettingsTab/UserSettingsTab';
import BrandingTab from './tabs/BrandingTab/BrandingTab';
import HelpSectionTab from './tabs/HelpSectionTab/HelpSectionTab';
import CountriesTab from './tabs/CountriesTab/CountriesTab';
import FilterSelect from '../../common/FilterSelect/FilterSelect';
import { withRouter } from 'react-router-dom'
import { Link } from "react-router-dom"
import { useStateValue } from '../../../state/state'
import { getPermission } from '../../../permissions'
import { useTranslation } from 'react-i18next'



function SettingsPage(props) {
  const { t } = useTranslation()
  const [{ userRole }] = useStateValue()
  const canSeeBrandingSettings = getPermission(userRole, 'canSeeBrandingSettings')
  const canSeeHelpSettings = getPermission(userRole, 'canSeeHelpSettings')
  const canSeeCountrisSettings = getPermission(userRole, 'canSeeCountrisSettings')

  const settingsTabs = [
    { value: 'user-settings', label: t('settings.userSettingsTab') },
    canSeeBrandingSettings && { value: 'branding', label: t('settings.brandingTab') },
    canSeeHelpSettings && { value: 'help-section', label: t('settings.helpSectionTab') },
    canSeeCountrisSettings && { value: 'countries', label: t('settings.countriesTab') },
  ]

  return (
    <div className="settings-page">
      <header className="header-desktop">
        <h1>{t('settings.title')}</h1>
      </header>

      <header className="header-mobile">
        <div className="title-line">
          <h3>{t('settings.titleMobile')}</h3>
          <div className="dropdown-wrapper">
            <FilterSelect 
              options={settingsTabs.filter(option => option)}
              selectedOption={settingsTabs.find(tab => tab.value === props.match.params.settingsTab)}
              changeHandler={option => props.history.push(option.value)}
              width="140px"
            />
          </div>
        </div>
      </header>

      <section className="tabs-wrapper-desktop">
        <div className="tabs-list">
          <Link to="/settings/user-settings" className={`tab text ${props.location.pathname.toString().includes('user-settings') && 'color-primary-default'}`}>{t('settings.userSettingsTab')}</Link>
          { canSeeBrandingSettings && <Link to="/settings/branding" className={`tab text ${props.location.pathname.toString().includes('branding') && 'color-primary-default'}`}>{t('settings.brandingTab')}</Link> }
          { canSeeHelpSettings && <Link to="/settings/help-section" className={`tab text ${props.location.pathname.toString().includes('help-section') && 'color-primary-default'}`}>{t('settings.helpSectionTab')}</Link> }
          { canSeeCountrisSettings && <Link to="/settings/countries" className={`tab text ${props.location.pathname.toString().includes('countries') && 'color-primary-default'}`}>{t('settings.countriesTab')}</Link> }
        </div>

        <div className="selected-tab">
          { props.location.pathname.toString().includes('user-settings') && <UserSettingsTab /> }
          { canSeeBrandingSettings && props.location.pathname.toString().includes('branding') && <BrandingTab /> }
          { canSeeHelpSettings && props.location.pathname.toString().includes('help-section') && <HelpSectionTab /> }
          { canSeeCountrisSettings && props.location.pathname.toString().includes('countries') && <CountriesTab /> }
        </div>
      </section>

      <section className="tabs-wrapper-mobile">
        { props.location.pathname.toString().includes('user-settings') && <UserSettingsTab /> }
        { canSeeBrandingSettings && props.location.pathname.toString().includes('branding') && <BrandingTab /> }
        { canSeeHelpSettings && props.location.pathname.toString().includes('help-section') && <HelpSectionTab /> }
        { canSeeCountrisSettings && props.location.pathname.toString().includes('countries') && <CountriesTab /> }
      </section>

    </div>
  );
}

export default withRouter(SettingsPage)
