import React, { useState } from 'react';
import Select, { components } from 'react-select'
import searchIcon from '../../../assets/search-icon.svg'


function MultichoiceSelect({ placeholder, options, selectedOptions, changeHandler, width, error }) {
  const [isOpen, setIsOpen] = useState(false) 


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '5px',
      border: state.isFocused 
        ? state.selectProps.error ? '1px solid red' : '1px solid #F6872D' 
        : state.selectProps.error ? '1px solid red' : '1px solid rgba(53, 71, 80, 0.5)',
      width: state.selectProps.width || '200px',
      minHeight: '50px',
      outline: 'none',
      boxShadow: 'none',
      fontSize: '15px',
     '&:hover': {
        boxShadow: 'none',
        border: state.selectProps.error ? '1px solid red' : '1px solid #F6872D',
      },
    }),
    indicatorSeparator: () => null,
    clearIndicator: () => ({
      display: 'none',
    }),
    placeholder: base => ({
      ...base,
      color: 'rgba(53, 71, 80, 0.5)',
    }),
    multiValue: base => ({
      ...base,
      backgroundColor: '#F6872D',
    }),
    multiValueLabel: base => ({
      ...base,
      color: 'white',
      fontSize: 12,
    }),
    multiValueRemove: base => ({
      ...base,
      backgroundColor: '#F6872D',
      color: '#fff',
      '&:hover': {
        color: '#fff',
        backgroundColor: 'rgba(246, 135, 45, 0.8)',
        cursor: 'pointer',
        borderRadius: '0 2px 2px 0',
      }
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? '#F6872D' : '#ffffff',
      '&:hover': {
        background: state.isSelected ? '#F6872D' : 'rgba(246, 135, 45, 0.2)',
      },
    }),
  }

  const IndicatorsContainer = props => {
    return (
      <div style={{ pointerEvents: 'none' }}>
        <components.IndicatorsContainer {...props} />
      </div>
    )
  }

  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {
            isOpen 
              ? <img src={searchIcon} alt="" />
              : <components.DownChevron />
          }
        </components.DropdownIndicator>
      )
    )
  }


  return (
    <Select
      placeholder={placeholder}
      options={options}
      isMulti
      value={selectedOptions}
      onChange={changeHandler}
      styles={customStyles}
      width={width}
      error={error}
      onMenuOpen={() => setIsOpen(true)}
      onMenuClose={() => setIsOpen(false)}
      components={{ IndicatorsContainer, DropdownIndicator }}
    />
  );
}

export default MultichoiceSelect;
