export const MAX_FILE_SIZE = 524288
export const ITEMS_PER_PAGE = 10

export const serverURL = process.env.REACT_APP_API_URL

export const defaultHelpText = `<h2>Work and Travel Calendar</h2>
<p>It is important to document working and non-work days during assignment to foreign countries, including the information, where these days have been spent (place of work in home country or assignment to which particular foreign country).</p>
<h2>Why is this information important?</h2>
<p>These records concerning working and non-work days are important with regards to tax liabilities. They serve as a basis to define what part of the income is subject to taxation in which countries, and whether it is possible to avoid a tax liability abroad.</p>
<p>The correct and reliable completion of this calendar is hence important to minimize risks and costs with regards to taxation.</p>
<h2>The following information shall be recorded:</h2>
<h3>1. Select the type of day</h3>
<p>Working day --&gt; Select "Working day" for a day you spent working</p>
<p>Non-Work-Day ---&gt; "Select "Non-Work-Day", if you have not been working on this day (e.g. weekends, holidays, illness)"</p>
<h3>2. Select the country</h3>
<p>Please report the country you have spent the respective day in with it's official country code. A list with countries and their codes can be found on the last excel worksheet ("country codes") of this document. The respective calendars provide a dropdown selection with the two-place codes available; independent typing is also possible, however.</p>
<h2>"What is considered as a day abroad / in a foreign country? When do I hence have to report a country code?"</h2>
<p>The following days are usually considered as a day abroad in international discourse:</p>
<ul>
  <li>Work days abroad (for entire days or only part of a day)</li>
  <li>Day of arrival</li>
  <li>Day of departure</li>
  <li>Weekends spent in the foreign country of work</li>
  <li>Holidays spent in the foreign country of work</li>
  <li>Vacation spent in the foreign country of work</li>
  <li>Short breaks spent in the foreign country of work</li>
  <li>Days of illness spent in the foreign country of work</li>
  <li>Other days spent in the foreign country of work</li>
</ul>`
