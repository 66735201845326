import React, { useState } from 'react'
import './SideMenu.scss'
import { Link } from "react-router-dom"
import { withRouter } from 'react-router-dom'
import { useStateValue } from '../../state/state'
import biztripsLogo from '../../assets/biztrips-logo.png'
import dashboardIcon from '../../assets/dashboard-icon.svg'
import reportIcon from '../../assets/report-icon.svg'
import projectsIcon from '../../assets/projects-icon.svg'
import usersIcon from '../../assets/users-icon.svg'
import helpIcon from '../../assets/help-icon.svg'
import settingsIcon from '../../assets/settings-icon.svg'
import logoutIcon from '../../assets/logout-icon.svg'
import hamburgerIcon from '../../assets/hamburger-icon.svg'
import closeIcon from '../../assets/close-icon-black.svg'
import { getPermission } from '../../permissions'
import { serverURL } from '../../config'
import { useTranslation } from 'react-i18next'


function SideMenu(props) {
  const [{ userRole, userData, settings }] = useStateValue()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const pathname = props.location.pathname
  const canSeeDashboardPage = getPermission(userRole, 'canSeeDashboardPage')
  const canSeeReportPage = getPermission(userRole, 'canSeeReportPage')
  const canSeeProjectsPage = getPermission(userRole, 'canSeeProjectsPage')
  const canSeeUsersPage = getPermission(userRole, 'canSeeUsersPage')
  const canSeeHelpPage = getPermission(userRole, 'canSeeHelpPage')
  const canSeeSettingsPage = getPermission(userRole, 'canSeeSettingsPage')
  const { t } = useTranslation()


  const showHiddenScrollbar = () => {
    document.body.style.overflow = 'visible'
    setMobileMenuOpen(false)
  }
  const hideScrollBar = () => {
    document.body.style.overflow = 'hidden'
    setMobileMenuOpen(true)
  }


  const renderMobileMenuIcon = () => {
    if (
      props.location.pathname.toString().includes('new-tracking')
      || props.location.pathname.toString().includes('new-user')
      || props.location.pathname.toString().includes('edit-user')
      || props.location.pathname.toString().includes('new-project')
      || props.location.pathname.toString().includes('edit-project')
    ) return null

    return !mobileMenuOpen 
      ? <img className="hamburger-menu-icon" src={hamburgerIcon} alt="" onClick={hideScrollBar} />
      : <img className="close-menu-icon" src={closeIcon} alt="" onClick={showHiddenScrollbar} />
  }

  const handleLogout = () => {
    document.cookie = 'userId=; Max-Age=-99999999;'
    document.cookie = 'token=; Max-Age=-99999999;'
    document.cookie = 'refreshToken=; Max-Age=-99999999;'

    document.cookie = `userId=; domain=${window.location.hostname}; Max-Age=-99999999;`
    document.cookie = `token=; domain=${window.location.hostname}; Max-Age=-99999999;`
    document.cookie = `refreshToken=; domain=${window.location.hostname}; Max-Age=-99999999;`

    const mainDomain = '.' + window.location.hostname.split('.').slice(1).join('.')
    document.cookie = `userId=; domain=${mainDomain}; Max-Age=-99999999;`
    document.cookie = `token=; domain=${mainDomain}; Max-Age=-99999999;`
    document.cookie = `refreshToken=; domain=${mainDomain}; Max-Age=-99999999;`
  }


  return (
    <>
      <div className="side-menu">
        <section className="top-links-wrapper">
          <Link className="logo-wrapper" to="/dashboard">
            { settings.hasOwnProperty('logo') && <img src={settings.logo ? serverURL + settings.logo.url : biztripsLogo} alt="logo" />}
          </Link>

          { canSeeDashboardPage && <Link className={`side-menu-link ${pathname === '/dashboard' && 'link-selected'}`} to="/dashboard">
            <span className="link-indicator"/>
            <img className="link-icon" src={dashboardIcon} alt="dashboard" />
            <span className="link-text">{t('menu.dashboard')}</span>
          </Link> }
          { canSeeReportPage && <Link className={`side-menu-link ${pathname === '/report' && 'link-selected'}`} to="/report">
            <span className="link-indicator"/>
            <img className="link-icon" src={reportIcon} alt="report" />
            <span className="link-text">{t('menu.report')}</span>
          </Link> }
          { canSeeProjectsPage && <Link className={`side-menu-link ${pathname === '/projects' && 'link-selected'}`} to="/projects">
            <span className="link-indicator"/>
            <img className="link-icon" src={projectsIcon} alt="projects" />
            <span className="link-text">{t('menu.projects')}</span>
          </Link> }
          { canSeeUsersPage && <Link className={`side-menu-link ${pathname === '/users' && 'link-selected'}`} to="/users">
            <span className="link-indicator"/>
            <img className="link-icon" src={usersIcon} alt="users" />
            <span className="link-text">{t('menu.users')}</span>
          </Link> }
        </section>

        <section className="bottom-links-wrapper">
          { canSeeHelpPage && <Link className={`side-menu-link ${pathname === '/help' && 'link-selected'}`} to="/help">
            <span className="link-indicator"/>
            <img className="link-icon" src={helpIcon} alt="help" />
            <span className="link-text">{t('menu.help')}</span>
          </Link> }
          { canSeeSettingsPage && <Link className={`side-menu-link ${pathname.includes('/settings') && 'link-selected'}`} to="/settings/user-settings">
            <span className="link-indicator"/>
            <img className="link-icon" src={settingsIcon} alt="help" />
            <span className="link-text">{t('menu.settings')}</span>
          </Link> }
          <Link className="side-menu-link" to="/" onClick={handleLogout}>
            <span className="link-indicator"/>
            <img className="link-icon" src={logoutIcon} alt="logout" />
            <span className="link-text">{t('menu.logout')}</span>
          </Link>
        </section>
      </div>

      <div className="hamburger-menu">
        { renderMobileMenuIcon() }

        <div className={`mobile-menu-overlay ${ mobileMenuOpen && 'overlay-visible'}`} onClick={showHiddenScrollbar}/>

        <div className={`mobile-menu ${!mobileMenuOpen && 'hidden'}`}>
          <section className="top-area">
            <h3>{t('menu.mobileGreetings')} <span className="color-primary-default">{userData.name}</span></h3>
          </section>

          <section className="bottom-area">
            { canSeeDashboardPage && <Link className={`side-menu-link ${pathname === '/dashboard' && 'link-selected'}`} to="/dashboard" onClick={showHiddenScrollbar}>
              <span className="link-indicator"/>
              <img className="link-icon" src={dashboardIcon} alt="dashboard" />
              <span className="link-text">{t('menu.dashboard')}</span>
            </Link> }
            { canSeeReportPage && <Link className={`side-menu-link ${pathname === '/report' && 'link-selected'}`} to="/report" onClick={showHiddenScrollbar}>
              <span className="link-indicator"/>
              <img className="link-icon" src={reportIcon} alt="report" />
              <span className="link-text">{t('menu.report')}</span>
            </Link> }
            { canSeeProjectsPage && <Link className={`side-menu-link ${pathname === '/projects' && 'link-selected'}`} to="/projects" onClick={showHiddenScrollbar}>
              <span className="link-indicator"/>
              <img className="link-icon" src={projectsIcon} alt="projects" />
              <span className="link-text">{t('menu.projects')}</span>
            </Link> }
            { canSeeUsersPage && <Link className={`side-menu-link ${pathname === '/users' && 'link-selected'}`} to="/users" onClick={showHiddenScrollbar}>
              <span className="link-indicator"/>
              <img className="link-icon" src={usersIcon} alt="users" />
              <span className="link-text">{t('menu.users')}</span>
            </Link> }
            <br/>
            { canSeeHelpPage && <Link className={`side-menu-link ${pathname === '/help' && 'link-selected'}`} to="/help" onClick={showHiddenScrollbar}>
              <span className="link-indicator"/>
              <img className="link-icon" src={helpIcon} alt="help" />
              <span className="link-text">{t('menu.help')}</span>
            </Link> }
            { canSeeSettingsPage && <Link className={`side-menu-link ${pathname.includes('/settings') && 'link-selected'}`} to="/settings/user-settings" onClick={showHiddenScrollbar}>
              <span className="link-indicator"/>
              <img className="link-icon" src={settingsIcon} alt="settings" />
              <span className="link-text">{t('menu.settings')}</span>
            </Link> }
            <Link className="side-menu-link" to="/" onClick={() => {
              handleLogout()
              showHiddenScrollbar()
             }}>
              <span className="link-indicator"/>
              <img className="link-icon" src={logoutIcon} alt="logout" />
              <span className="link-text">{t('menu.logout')}</span>
            </Link>
          </section>
        </div>
      </div>
    </>
  );
}

export default withRouter(SideMenu)
