import React, { useState, useEffect } from 'react';
import './ProjectsPage.scss';
import whitePlusIcon from '../../../assets/white-plus-icon.svg';
import arrowDown from '../../../assets/dropdown-arrow-down.svg';
import SortableHeader from '../../common/SortableHeader/SortableHeader';
import PopoverMenu from '../../common/PopoverMenu/PopoverMenu';
import NewProjectModal from './NewProjectModal/NewProjectModal';
import Snackbar from '../../common/Snackbar/Snackbar';
import Switch from '../../common/Switch/Switch';
import plusIcon from '../../../assets/plus-icon.svg';
import EditProjectModal from './EditProjectModal/EditProjectModal';
import { withRouter } from 'react-router-dom'
import { useStateValue } from '../../../state/state'
import { getPermission } from '../../../permissions'
import { ITEMS_PER_PAGE } from '../../../config'
import { getCookie } from '../../../utils'
import { default as axios } from '../../../axiosSettings'
import { useTranslation } from 'react-i18next'


function ProjectsPage(props) {
  const { t } = useTranslation()

  const renderStatusLabel = status => {
    if (status === 1) return <span className="label-active">{t('projects.activeLabel')}</span>
    if (status === 0) return <span className="label-inactive">{t('projects.inactiveLabel')}</span>
    if (status === 2) return <span className="label-archived">{t('projects.archivedLabel')}</span>
    return <>{status}</>
  }

  const [{ userRole }] = useStateValue()
  const [projects, setProjects] = useState([])
  const [projectsTotalCount, setProjectsTotalCount] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [sortBy, setSortBy] = useState('name')
  const [sortDirection, setSortDirection] = useState('asc')
  const [showArchived, setShowArchived] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const [editedRow, setEditedRow] = useState(null)
  const canCreateProject = getPermission(userRole, 'canCreateProject')
  const canEditProject = getPermission(userRole, 'canEditProject')
  const [limit, setLimit] = useState(0)
  const [offset, setOffset] = useState(0)
  const token = getCookie('token')


  function TableRow({ props, project, setEditedRow }) {
    const [{ userRole }] = useStateValue()
    const [showAllSupervisors, setShowAllSupervisors] = useState(false)
    const canEditProject = getPermission(userRole, 'canEditProject')
    const canActivateAndDeactivateProject = getPermission(userRole, 'canActivateAndDeactivateProject')
    const canArchiveAndReactivateProject = getPermission(userRole, 'canArchiveAndReactivateProject')
    
    const renderPopoverMenu = project => {
      if (project.status === 2 && !canArchiveAndReactivateProject) return null
      return <PopoverMenu>
        {renderPopoverMenuContent(project)}
      </PopoverMenu>
    }
  
    const renderPopoverMenuContent = project => {
      if (project.status === 1) {
        return <>
          { canEditProject && <div className="popover-menu-option" onClick={() => {
            setEditedRow(project)
            props.history.push('/projects/edit-project')
            window.scrollTo(0, 0)
          }}>{t('projects.editOption')}</div> }
          { canActivateAndDeactivateProject && <div className="popover-menu-option" onClick={() => changeProjectStatus(project.id, 0)}>{t('projects.deactivateOption')}</div> }
          { canArchiveAndReactivateProject && <div className="popover-menu-option" onClick={() => changeProjectStatus(project.id, 2)}>{t('projects.archiveOption')}</div> }
        </>
      }
      else if (project.status === 0) {
        return <>
          { canActivateAndDeactivateProject && <div className="popover-menu-option" onClick={() => changeProjectStatus(project.id, 1)}>{t('projects.activateOption')}</div> }
          { canArchiveAndReactivateProject && <div className="popover-menu-option" onClick={() => changeProjectStatus(project.id, 2)}>{t('projects.archiveOption')}</div> }
        </>
      }
      else if (project.status === 2) {
        return <>
          { canArchiveAndReactivateProject && <div className="popover-menu-option" onClick={() => changeProjectStatus(project.id, 1)}>{t('projects.activateOption')}</div> }
        </>
      }
    }
  
    return (
      <>
        <div className="table-cell text">{project.name}</div>
        <div className="table-cell text">
          <div className="supervisor-name-line">
            <span>{project.mainSupervisor ? project.mainSupervisor.name : '-'}</span>
            { project.supervisors.length > 1 && <img src={arrowDown} alt="" onClick={() => setShowAllSupervisors(!showAllSupervisors)} /> }
          </div>
          <div className="label color-text-light">{project.mainSupervisor ? project.mainSupervisor.email : '-'}</div>
          {
            showAllSupervisors && project.supervisors.map((supervisor, i) => 
              <div className="additional-supervisor" key={i}>
                <div className="supervisor-name-line">{supervisor && supervisor.name}</div>
                <div className="label color-text-light">{supervisor && supervisor.email}</div>
              </div>
            ) 
          }
        </div>
        <div className="table-cell text">{project.taxCode}</div>
        <div className="table-cell text">{renderStatusLabel(project.status)}</div>
        <div className="table-cell text">
          { renderPopoverMenu(project) }
        </div>
      </>
    )
  }


  useEffect(() => {
    getProjects()
  }, [sortBy, sortDirection, searchQuery, showArchived, limit])


  const getProjects = () => {
    const url = `api/projects?limit=${limit || ITEMS_PER_PAGE}&offset=0&sort_by=${sortBy}&sort_direction=${sortDirection}&search=${searchQuery}&show_archived=${showArchived}`
    axios.get(url, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        setProjects(response.data.items)
        setProjectsTotalCount(response.data.totalCount)
        setLimit(limit || (ITEMS_PER_PAGE < response.data.totalCount ? ITEMS_PER_PAGE : response.data.totalCount)) 
      })
  }


  const getMoreProjects = () => {
    const newOffset = offset + ITEMS_PER_PAGE
    const url = `api/projects?sort_by=${sortBy}&sort_direction=${sortDirection}&limit=${ITEMS_PER_PAGE}&offset=${newOffset}&search=${searchQuery}&show_archived=${showArchived}`
    axios.get(url, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        setOffset(newOffset)
        setProjects(projects => [...projects, ...response.data.items])
        setLimit(limit + ITEMS_PER_PAGE <= projectsTotalCount ? limit + ITEMS_PER_PAGE : projectsTotalCount) 
      })
  }


  const changeProjectStatus = (projectId, newStatus) => {
    axios.patch(`api/projects/${projectId}`, {
      status: newStatus
    }, {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/merge-patch+json',
      }
    })
      .then(() => getProjects())
  }


  return (
    <div className="projects-page">
      <header className="header-desktop">
        <h1>{t('projects.title')}</h1>
        { canCreateProject && <button className="button button-primary ripple-effect ripple-effect-lighter" onClick={() => props.history.push('/projects/new-project')}>
          <img src={whitePlusIcon} alt="" />
          {t('projects.newProjectButton')}
        </button> }
      </header>

      <header className="header-mobile">
        <div className="title-line">
          <h3>{t('projects.title')}</h3>
          <input type="text" className="search-bar" placeholder={t('projects.searchPlaceholder')} value={searchQuery} onChange={e => setSearchQuery(e.target.value) } />
        </div>
        <div className="switch-wrapper">
          <span className="label color-text-light">{t('projects.archivedSwitch')}:</span>
          <Switch active={showArchived} handleClick={() => setShowArchived(!showArchived)} />
        </div>
      </header>

      <section className="content-wrapper">
        <header>
          <div className="text bold color-text-light">{t('projects.tableTitle')}</div>
          <div className="title-right-area">
            <div className="switch-wrapper hide-small-screens">
              <span className="label color-text-light">{t('projects.archivedSwitch')}:</span>
              <Switch active={showArchived} handleClick={() => setShowArchived(!showArchived)} />
            </div>
            <input type="text" className="search-bar" placeholder={t('projects.searchPlaceholder')} value={searchQuery} onChange={e => setSearchQuery(e.target.value) } />
          </div>
        </header>

        <div className="table-headers">
          <SortableHeader text={t('projects.nameHeader')} sortByText="name" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
          <SortableHeader text={t('projects.subervisorHeader')} sortByText="mainSupervisor" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
          <SortableHeader text={t('projects.taxCodeHeader')} sortByText="taxCode" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
          <SortableHeader text={t('projects.statusHeader')} sortByText="status" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
          <div />
        </div>
        { 
          projects.map((project, i) => <div className="table-row" key={i}> 
            <TableRow props={props} project={project} setEditedRow={setEditedRow} />
          </div>) 
        }

        <section className="table-data">
          <div className="label color-text-light">{t('projects.showing')} {projects.length ? 1 : 0}-{Math.min(limit, projects.length)} {t('projects.of')} {projectsTotalCount} {t('projects.entries')}</div>
          {
            projectsTotalCount > limit && <button className="button button-secondary ripple-effect" onClick={() => {
              getMoreProjects()
            }}>{t('projects.showMoreButton')}</button>
          }
          <div />
        </section>
      </section>

      {
        !props.location.pathname.toString().includes('new-project') && !props.location.pathname.toString().includes('edit-project') && <section className="data-list-mobile show-small-screens">
          {
            projects.map((project, i) => <div className="list-item" key={i}>
              <div className="list-item-grid"> 
                <div className="item-cell">
                  <div className="text color-text-light">{t('projects.nameHeader')}</div>
                  <div className="text">{project.name}</div>
                </div>
                <div className="item-cell">
                  <div className="text">{renderStatusLabel(project.status)}</div>
                </div>
                <div className="item-cell">
                  <div className="text">{project.mainSupervisor ? project.mainSupervisor.name : '-'}</div>
                  <div className="label color-text-light">{project.mainSupervisor ? project.mainSupervisor.email : '-'}</div>
                </div>
                <div className="item-cell">
                  <div className="text color-text-light">{t('projects.taxCodeHeader')}</div>
                  <div className="text">{project.taxCode}</div>
                </div>
              </div>
              <div className="button-wrapper">
                { canEditProject && <button className="button button-secondary ripple-effect" onClick={() => {
                  window.scrollTo(0 ,0)
                  setEditedRow(project)
                  props.history.push('/projects/edit-project')
                }}>{t('projects.mobileEditOption')}</button> }
              </div>
            </div>)
          }
          <section className="table-data">
            <div className="label color-text-light">{t('projects.showing')} {projects.length ? 1 : 0}-{Math.min(limit, projects.length)} {t('projects.of')} {projectsTotalCount} {t('projects.entries')}</div>
            {
              projectsTotalCount > limit && <button className="button button-secondary ripple-effect" onClick={() => {
                getMoreProjects()
              }}>{t('projects.showMoreButton')}</button>
            }
          </section>
        </section>
      }

      {
        canCreateProject && props.location.pathname.toString().includes('new-project') && <NewProjectModal 
          cancelHandler={() => {
            props.history.push('/projects')
          }}
          closeAfterSuccessHandler={() => {
            props.history.push('/projects')
            setSuccessMessage(t('newProject.newProjectSuccessMessage'))
            setLimit(limit + 1)
          }}
        />
      }
      {
        successMessage && <Snackbar type="success" text={successMessage} closeHandler={() => setSuccessMessage(null)} />
      }
      {
        canCreateProject && !props.location.pathname.toString().includes('new-project') && <div className="add-button show-small-screens" onClick={() => {
          props.history.push('/projects/new-project')
          window.scrollTo(0 ,0)
        }}>
          <button className="button-content ripple-effect ripple-effect-lighter ripple-effect-circle">
            <img src={plusIcon} alt="add project" />
          </button>
        </div>
      }

      {
        canEditProject && props.location.pathname.toString().includes('edit-project') && editedRow && <EditProjectModal 
          projectData={editedRow}
          cancelHandler={() => {
            setEditedRow(false)
            props.history.push('/projects')
          }}
          closeAfterSuccessHandler={() => {
            setEditedRow(false)
            props.history.push('/projects')
            setSuccessMessage(t('editProject.editSuccessMessage'))
            getProjects()
          }}
        />
      }

    </div>
  )
}

export default withRouter(ProjectsPage)
