import React from 'react'
import './WorkingDaysComponent.scss'
import { useTranslation } from 'react-i18next'


function WorkingDaysComponent({ trackingsSummary }) {
  const { t } = useTranslation()


  return (
    <div className="working-days-component">
      <div className="color-bar" />
      <div className="left">
        <h2 className="color-workingday-default">{parseInt(trackingsSummary.confirmed_working_days_count || 0) + parseInt(trackingsSummary.unconfirmed_working_days_count || 0)}</h2>
        <div className="text color-text-light">{t('dashboard.workingDaysComponent.workingDays')}</div>
      </div>
      <div className="right">
        <h3>{trackingsSummary.confirmed_working_days_count || 0}</h3>
        <div className="text color-text-light">
          {t('dashboard.workingDaysComponent.confirmedPart1')}
          <span className="hide-small-screens">{t('dashboard.workingDaysComponent.confirmedPart2')}</span>
        </div>
        <h3>{trackingsSummary.unconfirmed_working_days_count || 0}</h3>
        <div className="text color-text-light">
          {t('dashboard.workingDaysComponent.unconfirmedPart1')}
          <span className="hide-small-screens">{t('dashboard.workingDaysComponent.unconfirmedPart2')}</span>
        </div>
      </div>
    </div>
  );
}

export default WorkingDaysComponent;
