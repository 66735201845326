import React from 'react'
import './DaysWithTrackingsComponent.scss'
import { useTranslation } from 'react-i18next'


function DaysWithTrackingsComponent({ trackingsSummary }) {
  const { t } = useTranslation()


  return (
    <div className="days-with-trackings-component">
      <div className="left">
        <h2>{parseInt(trackingsSummary.working_days_trackings || 0) + parseInt(trackingsSummary.non_working_days_trackings || 0)}</h2>
        <div className="text color-text-light">{t('dashboard.daysWithTrackingsComponent.allDays')}</div>
      </div>
      <div className="right">
        <h3>{trackingsSummary.working_days_trackings || 0}</h3>
        <div className="text color-text-light">
          {t('dashboard.daysWithTrackingsComponent.workingDays')}
        </div>
        <h3>{trackingsSummary.non_working_days_trackings || 0}</h3>
        <div className="text color-text-light">
          {t('dashboard.daysWithTrackingsComponent.nonWorkingDays')}
        </div>
      </div>
    </div>
  );
}

export default DaysWithTrackingsComponent
