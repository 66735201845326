import React, { useState } from 'react'
import Select, { components } from 'react-select'
import searchIcon from '../../../assets/search-icon.svg'


function FilterSelect({options, selectedOption, placeholder, changeHandler, width}) {
  const [isOpen, setIsOpen] = useState(false) 

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '5px',
      boxShadow: '5px 10px 12px rgba(206, 206, 206, 0.25)',
      border: 0,
      width: state.selectProps.width || '130px',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: isOpen ? 'rgba(53, 71, 80, 0.5)': 'initial',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#354750',
    }),
    indicatorSeparator: () => null,
    menu: (provided) => ({
      ...provided,
      zIndex: 20,
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? '#F6872D' : '#ffffff',
      '&:hover': {
        background: state.isSelected ? '#F6872D' : 'rgba(246, 135, 45, 0.2)',
      },
    }),
  }

  const IndicatorsContainer = props => {
    return (
      <div style={{ pointerEvents: 'none' }}>
        <components.IndicatorsContainer {...props} />
      </div>
    )
  }

  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {
            isOpen 
              ? <img src={searchIcon} alt="" />
              : <components.DownChevron />
          }
        </components.DropdownIndicator>
      )
    )
  }


  return (
    <Select
      placeholder={placeholder}
      styles={customStyles}
      value={selectedOption}
      onChange={changeHandler}
      options={options}
      width={width}
      onMenuOpen={() => setIsOpen(true)}
      onMenuClose={() => setIsOpen(false)}
      components={{ IndicatorsContainer, DropdownIndicator }}
    />
  )
}

export default FilterSelect
