import React, { useState, useEffect } from 'react'
import './UsersPage.scss'
import whitePlusIcon from '../../../assets/white-plus-icon.svg'
import SortableHeader from '../../common/SortableHeader/SortableHeader'
import NewUserModal from './NewUserModal/NewUserModal'
import PopoverMenu from '../../common/PopoverMenu/PopoverMenu'
import Snackbar from '../../common/Snackbar/Snackbar'
import Switch from '../../common/Switch/Switch'
import plusIcon from '../../../assets/plus-icon.svg'
import EditUserModal from './EditUserModal/EditUserModal'
import { withRouter } from 'react-router-dom'
import { useStateValue } from '../../../state/state'
import { getPermission } from '../../../permissions'
import { getCookie } from '../../../utils'
import { default as axios } from '../../../axiosSettings'
import { ITEMS_PER_PAGE } from '../../../config'
import { useTranslation } from 'react-i18next'


const ROLES_MAP = {
  'ROLE_USER': 'User',
  'ROLE_PM': 'Project manager',
  'ROLE_HR': 'HR',
}


function UsersPage(props) {
  const { t } = useTranslation()
  const [{ userRole }, dispatch] = useStateValue()
  const [users, setUsers] = useState([])
  const [usersTotalCount, setUsersTotalCount] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [sortBy, setSortBy] = useState('name')
  const [sortDirection, setSortDirection] = useState('asc')
  const [showArchived, setShowArchived] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const [warningMessage, setWarningMessage] = useState(null)
  const [editedRow, setEditedRow] = useState(null)
  const [limit, setLimit] = useState(0)
  const [offset, setOffset] = useState(0)
  const canCreateUser = getPermission(userRole, 'canCreateUser')
  const canEditUser = getPermission(userRole, 'canEditUser')
  const canActivateAndArchiveUser = getPermission(userRole, 'canActivateAndArchiveUser')
  const canChangeUserPassword = getPermission(userRole, 'canChangeUserPassword')
  const token = getCookie('token')


  useEffect(() => {
    getUsers()
  }, [sortBy, sortDirection, searchQuery, showArchived, limit])


  const getUsers = () => {
    const url = `api/users?limit=${limit || ITEMS_PER_PAGE}&offset=0&sort_by=${sortBy}&sort_direction=${sortDirection}&search=${searchQuery}&show_archived=${showArchived}`
    axios.get(url, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        setUsers(response.data.items)
        setUsersTotalCount(response.data.totalCount)
        setLimit(limit || (ITEMS_PER_PAGE < response.data.totalCount ? ITEMS_PER_PAGE : response.data.totalCount)) 
      })
  }

  const getMoreUsers = () => {
    const newOffset = offset + ITEMS_PER_PAGE
    const url = `api/users?sort_by=${sortBy}&sort_direction=${sortDirection}&limit=${ITEMS_PER_PAGE}&offset=${newOffset}`
    axios.get(url, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        setOffset(newOffset)
        setUsers(users => [...users, ...response.data.items])
        setLimit(limit + ITEMS_PER_PAGE <= usersTotalCount ? limit + ITEMS_PER_PAGE : usersTotalCount) 
      })
  }


  const archiveUser = userId => {
    if (userId == getCookie('userId')) {
      setWarningMessage(t('users.archiveYourselfWarning'))
      return null
    }

    axios.patch(`api/users/${userId}`, {
      status: 2
    }, {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/merge-patch+json',
      }
    })
      .then(() => getUsers())
  }


  const activateUser = userId => {
    axios.patch(`api/users/${userId}`, {
      status: 1
    }, {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/merge-patch+json',
      }
    })
      .then(() => getUsers())
  }


  const renderStatusLabel = status => {
    if (status === 1) return <span className="label-active">{t('users.activeLabel')}</span>
    if (status === 2) return <span className="label-archived">{t('users.archivedLabel')}</span>
    return <>{status}</>
  }

  const renderPopoverMenuContent = user => {
    if (user.status === 1) {
      return <>
        { canEditUser && <div className="popover-menu-option" onClick={() => {
          setEditedRow(user) 
          props.history.push('/users/edit-user')
          dispatch({ type: 'setData', field: 'changePasswordMode', data: false })
          window.scrollTo(0 ,0)
        }}>{t('users.editOption')}</div> }
        { canActivateAndArchiveUser && <div className="popover-menu-option" onClick={() => archiveUser(user.id)}>{t('users.archiveOption')}</div> }
        { canChangeUserPassword && <div className="popover-menu-option" onClick={() => {
          setEditedRow(user) 
          props.history.push('/users/edit-user')
          dispatch({ type: 'setData', field: 'changePasswordMode', data: true })
          window.scrollTo(0 ,0)
        }}>{t('users.changePasswordOption')}</div> }
      </>
    }
    else if (user.status === 2) {
      return <>
        { canActivateAndArchiveUser && <div className="popover-menu-option" onClick={() => activateUser(user.id)}>{t('users.activateOption')}</div> }
      </>
    }
  }

  
  return (
    <div className="users-page">
      <header className="header-desktop">
        <h1>{t('users.title')}</h1>
        { canCreateUser && <button className="button button-primary ripple-effect ripple-effect-lighter" onClick={() => props.history.push('users/new-user')}>
          <img src={whitePlusIcon} alt="" />
          {t('users.newUserButton')}
        </button> }
      </header>

      <header className="header-mobile">
        <div className="title-line">
          <h3>{t('users.title')}</h3>
          <input type="text" className="search-bar" placeholder={t('users.searchPlaceholder')} value={searchQuery} onChange={e => setSearchQuery(e.target.value) } />
        </div>
        <div className="switch-wrapper">
          <span className="label color-text-light">{t('users.archivedSwitch')}:</span>
          <Switch active={showArchived} handleClick={() => setShowArchived(!showArchived)} />
        </div>
      </header>

      <section className="content-wrapper">
        <header>
          <div className="text bold color-text-light">{t('users.tableTitle')}</div>
          <div className="title-right-area">
            <div className="switch-wrapper hide-small-screens">
              <span className="label color-text-light">{t('users.archivedSwitch')}:</span>
              <Switch active={showArchived} handleClick={() => setShowArchived(!showArchived)} />
            </div>
            <input type="text" className="search-bar" placeholder={t('users.searchPlaceholder')} value={searchQuery} onChange={e => setSearchQuery(e.target.value) } />
          </div>
        </header>

        <div className="table-headers">
          <SortableHeader text={t('users.nameHeader')} sortByText="name" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
          <SortableHeader text={t('users.employeeNumberHeader')} sortByText="employeeNumber" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
          <SortableHeader text={t('users.roleHeader')} sortByText="role" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
          <SortableHeader text={t('users.statusHeader')} sortByText="status" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
          <div />
        </div>
        { 
          users.map((user, i) => <div className="table-row" key={i}> 
            <div className="table-cell text">
              <div>{user.name || '-'}</div>
              <div className="label color-text-light">{user.email || '='}</div>
            </div>
            <div className="table-cell text">{user.employeeNumber || '-'}</div>
            <div className="table-cell text">{ROLES_MAP[user.roles[0]]}</div>
            <div className="table-cell text">{user.status ? renderStatusLabel(user.status) : '-'}</div>
            <div className="table-cell last-cell text">
              <PopoverMenu>
                {renderPopoverMenuContent(user)}
              </PopoverMenu>
            </div>
          </div>) 
        }

        <section className="table-data">
          <div className="label color-text-light">{t('users.showing')} {users.length ? 1 : 0}-{Math.min(limit, users.length)} {t('users.of')} {usersTotalCount} {t('users.entries')}</div>
          {
            usersTotalCount > limit && <button className="button button-secondary ripple-effect" onClick={() => {
              getMoreUsers()
            }}>{t('users.showMoreButton')}</button>
          }
          <div />
        </section>
      </section>

      {
        !props.location.pathname.toString().includes('new-user') && !props.location.pathname.toString().includes('edit-user') && <section className="data-list-mobile show-small-screens">
          {
            users.map((user, i) => <div className="list-item" key={i}>
              <div className="list-item-grid"> 
                <div className="item-cell">
                  <div className="text">{user.name}</div>
                  <div className="label color-text-light">{user.email}</div>
                </div>
                <div className="item-cell">
                  <div className="text">{renderStatusLabel(user.status)}</div>
                </div>
                <div className="item-cell">
                  <div className="text color-text-light">{t('users.employeeNumberHeader')}</div>
                  <div className="text">{user.employeeNumber}</div>
                </div>
                <div className="item-cell">
                  <div className="text color-text-light">{t('users.roleHeader')}</div>
                  <div className="text">{user.role}</div>
                </div>
              </div>
              <div className="button-wrapper">
                { canEditUser && <button className="button button-secondary ripple-effect" onClick={() => {
                  window.scrollTo(0 ,0)
                  setEditedRow(user)
                  props.history.push('/users/edit-user')
                }}>{t('users.editUserButton')}</button> }
              </div>
            </div>)
          }
          <section className="table-data">
          <div className="label color-text-light">{t('users.showing')} {users.length ? 1 : 0}-{Math.min(limit, users.length)} {t('users.of')} {usersTotalCount} {t('users.entries')}</div>
            {
              usersTotalCount > limit && <button className="button button-secondary ripple-effect" onClick={() => {
                getMoreUsers()
              }}>{t('users.showMoreButton')}</button>
            }
          </section>
        </section>
      }

      {
        canCreateUser && props.location.pathname.toString().includes('new-user') && <NewUserModal 
          cancelHandler={() => {
            props.history.push('/users')
          }}
          closeAfterSuccessHandler={() => {
            props.history.push('/users')
            setSuccessMessage('New user has been created.')
            setLimit(limit + 1)
          }}
        />
      }
      {
        successMessage && <Snackbar type="success" text={successMessage} closeHandler={() => setSuccessMessage(null)} />
      }
      {
        warningMessage && <Snackbar type="warning" text={warningMessage} closeHandler={() => setWarningMessage(null)} />
      }
      {
        canCreateUser && !props.location.pathname.toString().includes('new-user') && <div className="add-button show-small-screens" onClick={() => {
          props.history.push('/users/new-user')
          window.scrollTo(0, 0)
        }}>
          <button className="button-content ripple-effect ripple-effect-lighter ripple-effect-circle">
            <img src={plusIcon} alt="add user" />
          </button>
        </div>
      }

      {
        canEditUser && props.location.pathname.toString().includes('edit-user') && editedRow && <EditUserModal
          userData={editedRow}
          cancelHandler={() => {
            setEditedRow(null)
            props.history.push('/users')
          }}
          closeAfterSuccessHandler={() => {
            setEditedRow(null)
            props.history.push('/users')
            setSuccessMessage(t('editUser.editSuccessMessage'))
            getUsers()
          }}
        />
      }

    </div>
  )
}

export default withRouter(UsersPage)
