import React from 'react'
import './CountryBar.scss'
import { useTranslation } from 'react-i18next'


function CountryBar({ tracking, filterStartDate, filterEndDate }) {
  const { t } = useTranslation()

  if (!tracking) return null

  const workingDaysPercent = tracking.working_days / tracking.max_working_days * 100
  const nonWorkingDaysPercent = tracking.non_working_days / tracking.max_working_days * 100
  const startDate = filterStartDate ? filterStartDate.split('T')[0] : tracking.tax_period_start
  const endDate = filterEndDate ? filterEndDate.split('T')[0] : tracking.tax_period_end
  const limitExceeded = parseInt(tracking.working_days) + parseInt(tracking.non_working_days) > tracking.max_working_days


  return (
    <div className="country-bar">
      <div className="label">
        <span className={limitExceeded ? 'color-red' : ''}>{t(`settings.countryCodes.${tracking.name}`)} ({startDate} - {endDate})</span>
        <span className={limitExceeded ? 'color-red' : ''}>{t('dashboard.trackingByCountriesComponent.max')}. {tracking.max_working_days} wd</span>
      </div>
      {
        !limitExceeded && <div className="bar-wrapper">
          {
            workingDaysPercent > 0 && <div className="progress-bar bar-working-days" style={{width: `${workingDaysPercent}%`}}>{tracking.working_days} wd</div>
          }
          {
            nonWorkingDaysPercent > 0 && <div className="progress-bar bar-non-working-days" style={{width: `${nonWorkingDaysPercent}%`}}>{tracking.non_working_days} nwd</div>
          }
        </div>
      }
      {
        limitExceeded && <div className="bar-limit-exceeded">
          {t('dashboard.trackingByCountriesComponent.exceededBy')} {tracking.working_days - tracking.max_working_days} {t('dashboard.trackingByCountriesComponent.workingDays')}
        </div>
      }
    </div>
  );
}

export default CountryBar;
