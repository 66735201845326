import React, { useState, useEffect, useRef } from 'react'
import './DragAndDropFileUpload.scss'
import uploadIcon from '../../../assets/upload-icon.svg'
import { useTranslation } from 'react-i18next'


function DragAndDropFileUpload(props) {
  const { t } = useTranslation()
  const [dragActive, setDragActive] = useState(false)
  const dropRef = useRef(null)
  let dragCounter = 0

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dragCounter++
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragActive(true)
    }
  }
  
  const handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dragCounter--
    if (dragCounter === 0) {
      setDragActive(false)
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      props.handleDrop(e.dataTransfer.files)
      e.dataTransfer.clearData()
      dragCounter = 0    
    }
  }

  useEffect(() => {
    dropRef.current.addEventListener('dragenter', handleDragIn)
    dropRef.current.addEventListener('dragleave', handleDragOut)
    dropRef.current.addEventListener('dragover', handleDrag)
    dropRef.current.addEventListener('drop', handleDrop)
  
    return () => {
      dropRef.current.removeEventListener('dragenter', handleDragIn)
      dropRef.current.removeEventListener('dragleave', handleDragOut)
      dropRef.current.removeEventListener('dragover', handleDrag)
      dropRef.current.removeEventListener('drop', handleDrop)
    }
  }, [])


  return (
    <div className="drag-and-drop-file-upload" ref={dropRef}>
      {
        dragActive && <div className="drop-active-overlay">{t('dragAndDropComponent.dropHere')}</div>
      }
      <img src={uploadIcon} alt="upload" />
      <span className="text color-primary-default">{t('dragAndDropComponent.dragAndDrop')}</span>
      <span className="text">{t('dragAndDropComponent.or')}</span>
      <span className="browse-button">
        <input type="file" name="file" id="file" className="inputfile" accept="image/png, image/jpg, image/jpeg" onChange={e => props.handleDrop(e.target.files)} />
        <label htmlFor="file">{t('dragAndDropComponent.browseFiles')}</label>
      </span>
    </div>
  );
}

export default DragAndDropFileUpload;
