import React, { useState, useEffect } from 'react'
import './TrackingByCountriesComponent.scss'
import CountryBar from '../../../../common/CountryBar/CountryBar'
import AllCountriesModal from './AllCountriesModal/AllCountriesModal'
import emptyTrackingsImage from '../../../../../assets/empty-trackings-image.svg'
import { useStateValue } from '../../../../../state/state'
import { withRouter } from 'react-router-dom'
import { getCookie } from '../../../../../utils'
import { default as axios } from '../../../../../axiosSettings'
import CountryChart from '../../../../common/CountryChart/CountryChart'
import { useTranslation } from 'react-i18next'


function TrackingByCountriesComponent({
  history,
  location,
  usersFilterOption,
  projectsFilterOption,
  countriesFilterOption,
  statusesFilterOption,
  startDate,
  endDate,
  taxPeriodSelected,
}) {
  const [{ userRole, refreshTrackings }, dispatch] = useStateValue()
  const [trackings, setTrackings] = useState([])
  const token = getCookie('token')
  const { t } = useTranslation()


  useEffect(() => {
    getTrackingsByCountries()
    dispatch({ type: 'setData', field: 'refreshTrackings', data: false })
  }, [refreshTrackings])


  useEffect(() => {
    getTrackingsByCountries()
  }, [
    usersFilterOption,
    projectsFilterOption,
    countriesFilterOption,
    statusesFilterOption,
    startDate,
    endDate,
    taxPeriodSelected,
  ])


  const getTrackingsByCountries = () => {
    if (!usersFilterOption) return

    let url = `api/trackings/per-country?`
    if (usersFilterOption) url += `user=${usersFilterOption.value}&`
    if (projectsFilterOption && projectsFilterOption.value !== 'all') url += `project=${projectsFilterOption.value}&`
    if (countriesFilterOption && countriesFilterOption.value !== 'all') url += `country=${countriesFilterOption.value}&`
    if (statusesFilterOption && statusesFilterOption.value !== 'all') url += `status=${statusesFilterOption.value}&`
    if (startDate) url += `start_date=${startDate.split('T')[0]}&`
    if (endDate) url += `end_date=${endDate.split('T')[0]}&`
    if (taxPeriodSelected) url += `current_tax_period=1&`

    axios.get(url, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        setTrackings(response.data)
      })
  }


  return (
    <div className="tracking-by-countries-component">
      <div className="text bold color-text-light">{t('dashboard.trackingByCountriesComponent.title')}</div>
      {
        trackings.length === 0 && <img src={emptyTrackingsImage} className="empty-image" alt="no tracking" />
      }
      {
        trackings
          .sort((a, b) => a.max_working_days - a.working_days < b.max_working_days - b.working_days ? -1 : 1)
          .slice(0, 5).map((tracking, i) => {
            return <div key={i} className="country-bar-wrapper">
            {
              userRole && userRole !== 'ROLE_USER' && usersFilterOption && usersFilterOption.value === 'all'
                ? <CountryChart tracking={tracking} filterStartDate={startDate} filterEndDate={endDate} />
                : <CountryBar tracking={tracking} filterStartDate={startDate} filterEndDate={endDate} />
            }
          </div>
          })
      }
      {
        trackings.length !== 0 
          ? <div className="all-countries-button" onClick={() => history.push('/dashboard/all-countries')}>{t('dashboard.trackingByCountriesComponent.modalLinkText')}</div>
          : <div className="all-countries-button all-countries-button-inactive">{t('dashboard.trackingByCountriesComponent.modalLinkText')}</div>
      }
      { 
        location.pathname.toString().includes('all-countries') && 
          <AllCountriesModal 
            trackings={trackings} 
            usersFilterOption={usersFilterOption}
            projectsFilterOption={projectsFilterOption}
            countriesFilterOption={countriesFilterOption}
            statusesFilterOption={statusesFilterOption}
            startDate={startDate}
            endDate={endDate}
            taxPeriodSelected={taxPeriodSelected}
            closeModalHandler={() => history.push('/dashboard')} /> 
      }
    </div>
  )
}

export default withRouter(TrackingByCountriesComponent)
