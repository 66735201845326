import React, { useState, useEffect } from 'react'
import './BrandingTab.scss'
import defaultLogo from '../../../../../assets/default-logo.png'
import { useStateValue } from '../../../../../state/state'
import DragAndDropFileUpload from '../../../../common/DragAndDropFileUpload/DragAndDropFileUpload'
import { MAX_FILE_SIZE, serverURL } from '../../../../../config'
import Snackbar from '../../../../common/Snackbar/Snackbar'
import { getCookie } from '../../../../../utils'
import { default as axios } from '../../../../../axiosSettings'
import { useTranslation } from 'react-i18next'


function BrandingTab() {
  const { t } = useTranslation()
  const [{ settings }, dispatch] = useStateValue()
  const [uploadedLogo, setUploadedLogo] = useState(null)
  const [fileUploadWarning, setFileUploadWarning] = useState(null)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [successMessage, setSuccessMessage] = useState(null)
  const token = getCookie('token')
  const currentLogo = settings.logo ? serverURL + settings.logo.url : defaultLogo


  useEffect(() => {
    axios.get(`api/settings/`, { 
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
      .then(response => {
        dispatch({ type: 'setData', field: 'settings', data: response.data[0] })
      })
  }, [])


  useEffect(() => {
    setIsButtonDisabled(
      !uploadedLogo
    )
  }, [uploadedLogo])


  const handleLogoSave = () => {
    const formData = new FormData()
    formData.append('file', uploadedLogo)

    axios.post(`api/files/logo`, formData, { 
      headers: { 
        Authorization: `bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      } 
    })
      .then(response => {
        axios.patch(`api/settings/1`, {
          logo: `api/files/logo/${response.data.id}`,
        }, { 
          headers: { 
            Authorization: `bearer ${token}`,
            'Content-Type': 'application/merge-patch+json',
          } 
        })
          .then(response => {
            dispatch({ type: 'setData', field: 'settings', data: response.data })
            setSuccessMessage(t('settings.branding.updateSuccessMessage'))
          })
      })
  }


  const setDefaultLogo = () => {
    axios.patch(`api/settings/1`, {
      logo: null,
    }, { 
      headers: { 
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/merge-patch+json',
      } 
    })
      .then(response => {
        dispatch({ type: 'setData', field: 'settings', data: response.data })
        setUploadedLogo(null)
        setSuccessMessage(t('settings.branding.resetSuccessMessage'))
      })
  }


  const handleDrop = (files) => {
    if (files[0] && files[0].name) {
      if (files[0].size > MAX_FILE_SIZE) { // validate image size
        setFileUploadWarning(t('settings.branding.incorrectFileSizeMessage'))
      } 
      else if (files[0].type !== 'image/jpeg' && files[0].type !== 'image/jpg' && files[0].type !== 'image/png') { // validate image type
        setFileUploadWarning(t('settings.branding.incorrectFileTypeMessage'))
      }
      else {
        const imageToUpload = files[0]
        const reader = new FileReader()
        reader.readAsDataURL(files[0])
        reader.onload = function (e) {
          const image = new Image()
          image.src = e.target.result
          image.onload = function () {
            if (this.height <= 100 && this.width <= 100) { // validate image dimensions
              setUploadedLogo(imageToUpload)
            } else {
              setFileUploadWarning(t('settings.branding.incorrectFileDimensionsMessage'))
            }
          }
        }
      }
      document.querySelector('#file').value = null
    }
  }
  

  return (
    <div className="branding-tab">
      <section>
        <div className="label">{t('settings.branding.uploadNewLogoLabel')}</div>
        <DragAndDropFileUpload handleDrop={handleDrop} />
        <div className="label color-text-light">{t('settings.branding.logoRequirements')}</div>
      </section>

      <section>
        <div className="label">{t('settings.branding.logoPreviewLabel')}</div>
        { settings.hasOwnProperty('logo') && <img src={ uploadedLogo ? window.URL.createObjectURL(uploadedLogo) : currentLogo } 
          className="logo-preview" 
          alt="logo preview" /> }
      </section>

      <section className="buttons-wrapper">
        <div className="label">{t('settings.branding.logoResetLabel')}</div>
        <button className="button button-secondary ripple-effect" onClick={setDefaultLogo}>{t('settings.branding.defaultLogo')}</button>
      </section>

      <div className="buttons-wrapper">
        <button className="button button-secondary ripple-effect" onClick={() => setUploadedLogo(null)}>{t('settings.branding.cancelButton')}</button>
        <button className={`button button-primary ripple-effect ripple-effect-lighter ${isButtonDisabled && 'button-primary-disabled'}`} onClick={handleLogoSave}>{t('settings.branding.saveButton')}</button>
      </div>

      {
        fileUploadWarning && <Snackbar type="warning" text={fileUploadWarning} closeHandler={() => setFileUploadWarning(false)} />
      }
      {
        successMessage && <Snackbar type="success" text={successMessage} closeHandler={() => setSuccessMessage(null)} />
      }
    </div>
  )
}

export default BrandingTab
