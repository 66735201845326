import React from 'react';
import './Switch.scss';


function Switch({ active, handleClick }) {

  return (
    <div className="switch">
      <div className={`switch-background ${active && 'switch-background-blue'}`} onClick={handleClick}>
        <div className={`switch-indicator ${active && 'switch-indicator-active'}`} />
      </div>
    </div>
  )
}

export default Switch