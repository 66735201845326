import React, { useState, useEffect } from 'react'
import './NewUserModal.scss'
import SelectInput from '../../../common/SelectInput/SelectInput'
import Snackbar from '../../../common/Snackbar/Snackbar'
import { validateEmail, validatePassword, getCookie } from '../../../../utils'
import { default as axios } from '../../../../axiosSettings'
import { useTranslation } from 'react-i18next'


const rolesOptions = [
  { value: 'ROLE_USER', label: 'User' },
  { value: 'ROLE_PM', label: 'Project leader' },
  { value: 'ROLE_HR', label: 'HR' },
]


function NewUserModal({ cancelHandler, closeAfterSuccessHandler }) {
  const { t } = useTranslation()
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState(false)
  const [employeeNumber, setEmployeeNumber] = useState('')
  const [role, setRole] = useState(null)
  const [roleError, setRoleError] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [validationFailedError, setValidationFailedError] = useState(null)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)


  useEffect(() => {
    setIsButtonDisabled(!name || !role || !email || !password)
  }, [name, role, email, password])


  const validateFormData = () => {
    const nameError = !name
    const roleError = role === null
    const emailError = !validateEmail(email)
    const passwordError = !validatePassword(password)

    if (
        !nameError
        && !roleError
        && !emailError
        && !passwordError
      ) {
      createNewUser()
      setValidationFailedError(null)
    } else {
      setValidationFailedError(t('newUser.validationErrorMessage'))
    }

    setNameError(nameError)
    setRoleError(roleError)
    setEmailError(emailError)
    setPasswordError(passwordError)
  }

  
  const createNewUser = () => {
    const token = getCookie('token')
    axios.post(`api/users`, {
      email: email,
      roles: [role.value],
      password: password,
      name: name,
      employeeNumber: employeeNumber,
      status: 1,
      language: "",
    }, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(() => {
        closeAfterSuccessHandler()
      })
  }


  return (
    <div className="new-user-modal">
      <div className="modal-content">
        <h2>{t('newUser.title')}</h2>

        <div className="inputs">

          <div className="input-wrapper">
            <div className="label">{t('newUser.nameLabel')}</div>
            <input className={`text-input w-250 ${nameError && 'border-red'}`} type="text" placeholder={t('newUser.namePlaceholder')} value={name} onChange={e => setName(e.target.value)} />
          </div>

          <div className="input-wrapper">
            <div className="label">{t('newUser.employeeNumberLabel')}</div>
            <input className="text-input w-250" type="text" placeholder={t('newUser.employeeNumberPlaceholder')} value={employeeNumber} onChange={e => setEmployeeNumber(e.target.value)} />
          </div>

          <div className="input-wrapper">
            <div className="label">{t('newUser.roleLabel')}</div>
            <SelectInput
              options={rolesOptions}
              selectedOption={role}
              changeHandler={option => setRole(option)}
              width='200px'
              error={roleError}
            />
          </div>

          <div className="input-wrapper">
            <div className="label">{t('newUser.emailLabel')}</div>
            <input className={`text-input w-250 ${emailError && 'border-red'}`} type="text" placeholder={t('newUser.emailPlaceholder')} value={email} onChange={e => setEmail(e.target.value)} />
          </div>

          <div className="input-wrapper">
            <div className="label">{t('newUser.passwordLabel')}</div>
            <input className={`text-input w-250 ${passwordError && 'border-red'}`} type="password" placeholder={t('newUser.passwordPlaceholder')} value={password} onChange={e => setPassword(e.target.value)} />
            <div className="help-text">{t('newUser.passwordRequirements')}</div>         
          </div>
        </div>

        <div className="input-wrapper buttons-wrapper">
          <button className="button button-secondary ripple-effect" onClick={cancelHandler}>{t('newUser.cancelButton')}</button>
          <button className={`button button-primary ripple-effect ripple-effect-lighter ${isButtonDisabled && 'button-primary-disabled'}`} onClick={validateFormData}>{t('newUser.createUserButton')}</button>
        </div>
      </div>

      {
        validationFailedError && <Snackbar type="error" text={validationFailedError} closeHandler={() => setValidationFailedError(null)} />
      }

    </div>
  );
}

export default NewUserModal
