import React from 'react';
import './CalendarInput.scss';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import calendarIcon from '../../../assets/calendar-icon.svg';
import 'moment/locale/de'
import { useTranslation } from 'react-i18next'


function CalendarInput({ value, placeholder, changeHandler, error }) {
  const { t, i18n } = useTranslation()
  const format = "DD/MM/YYYY"


  return (
    <div className={`calendar-input ${error && 'red-border'}`}>
      <DayPickerInput 
        dayPickerProps={{localeUtils: MomentLocaleUtils, locale: i18n.language}}
        value={value && formatDate(new Date(value), format)}
        format={format}
        formatDate={formatDate}
        parseDate={parseDate}
        placeholder={placeholder}
        onDayChange={changeHandler} 
      />
      <img src={calendarIcon} alt="" />
    </div>
  );
}

export default CalendarInput
