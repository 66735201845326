const ROLE_HR_PERMISSIONS = {
  // pages
  canSeeDashboardPage: true,
  canSeeReportPage: true,
  canSeeProjectsPage: true,
  canSeeUsersPage: true,
  canSeeHelpPage: true,
  canSeeSettingsPage: true,
  // filters
  canSeeUsersFilter: true,
  canSeeProjectsFilter: true,
  canSeeCountriesFilter: true,
  canSeeStatusFilter: true,
  canSeeDateFilter: true,
  // dashboardComponents
  canSeeDaysWithTrackings: true,
  // trackings
  canEditTracking: true,
  canDeleteTracking: true,
  canConfirmTracking: true,
  canCloseTracking: true,
  // reports
  canExportCSVFile: true,
  canExportPDFFile: true,
  // projects
  canCreateProject: true,
  canEditProject: true,
  canActivateAndDeactivateProject: true,
  canArchiveAndReactivateProject: true,
  // users
  canCreateUser: true,
  canEditUser: true,
  canActivateAndArchiveUser: true,
  canChangeUserPassword: true,
  // settings
  canSeeBrandingSettings: true,
  canSeeHelpSettings: true,
  canSeeCountrisSettings: true,
}

const ROLE_PM_PERMISSIONS = {
  // pages
  canSeeDashboardPage: true,
  canSeeReportPage: true,
  canSeeProjectsPage: true,
  canSeeUsersPage: false,
  canSeeHelpPage: true,
  canSeeSettingsPage: true,
  // filters
  canSeeUsersFilter: true,
  canSeeProjectsFilter: true,
  canSeeCountriesFilter: true,
  canSeeStatusFilter: true,
  canSeeDateFilter: true,
  // dashboardComponents
  canSeeDaysWithTrackings: true,
  // trackings
  canEditTracking: true,
  canDeleteTracking: true,
  canConfirmTracking: true,
  canCloseTracking: false,
  // reports
  canExportCSVFile: true,
  canExportPDFFile: true,
  // projects
  canCreateProject: true,
  canEditProject: true,
  canActivateAndDeactivateProject: true,
  canArchiveAndReactivateProject: false,
  // users
  canCreateUser: false,
  canEditUser: false,
  canActivateAndArchiveUser: false,
  canChangeUserPassword: false,
  // settings
  canSeeBrandingSettings: false,
  canSeeHelpSettings: false,
  canSeeCountrisSettings: false,
}

const ROLE_USER_PERMISSIONS = {
  // pages
  canSeeDashboardPage: true,
  canSeeReportPage: true,
  canSeeProjectsPage: false,
  canSeeUsersPage: false,
  canSeeHelpPage: true,
  canSeeSettingsPage: true,
  // filters
  canSeeUsersFilter: false,
  canSeeProjectsFilter: true,
  canSeeCountriesFilter: true,
  canSeeStatusFilter: true,
  canSeeDateFilter: true,
  // dashboardComponents
  canSeeDaysWithTrackings: false,
  // trackings
  canEditTracking: true,
  canDeleteTracking: true,
  canConfirmTracking: true,
  canCloseTracking: false,
  // reports
  canExportCSVFile: true,
  canExportPDFFile: true,
  // projects
  canCreateProject: false,
  canEditProject: false,
  canActivateAndDeactivateProject: false,
  canArchiveAndReactivateProject: false,
  // users
  canCreateUser: false,
  canEditUser: false,
  canActivateAndArchiveUser: false,
  canChangeUserPassword: false,
  // settings
  canSeeBrandingSettings: false,
  canSeeHelpSettings: false,
  canSeeCountrisSettings: false,
}

export const getPermission = (role, permission) => {
  if (role === 'ROLE_HR') {
    return ROLE_HR_PERMISSIONS[permission]
  }
  else if (role === 'ROLE_PM') {
    return ROLE_PM_PERMISSIONS[permission]
  }
  else if (role === 'ROLE_USER') {
    return ROLE_USER_PERMISSIONS[permission]
  }
}
