import React, { useState, useEffect } from 'react'
import './CountriesTab.scss'
import SortableHeader from '../../../../common/SortableHeader/SortableHeader'
import moment from 'moment'
import { getCookie } from '../../../../../utils'
import { default as axios } from '../../../../../axiosSettings'
import { useTranslation } from 'react-i18next'



function CountriesTab() {
  const { t } = useTranslation()
  const [countries, setCountries] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [sortBy, setSortBy] = useState('name')
  const [sortDirection, setSortDirection] = useState('asc')
  const token = getCookie('token')


  const renderTaxPeriod = (taxPeriodStart, taxPeriodEnd) => {
    if (!taxPeriodStart || !taxPeriodEnd) return '-'
    const startDate = moment(taxPeriodStart).format('DD.MM.YYYY')
    const endDate = moment(taxPeriodEnd).format('DD.MM.YYYY')
    return `${startDate} - ${endDate}`
  }


  useEffect(() => {
    getCountries()
  }, [sortBy, sortDirection, searchQuery])


  const getCountries = () => {
    const url = `api/countries?&sort_by=${sortBy}&sort_direction=${sortDirection}&search=${searchQuery}`
    axios.get(url, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        setCountries(response.data)
      })
  }


  return (
    <div className="countries-tab">

      <div className="search-line">
        <input type="text" className="search-bar" placeholder={t('settings.countries.searchInputPlaceholder')} value={searchQuery} onChange={e => setSearchQuery(e.target.value) } />
      </div> 

      <section className="table-wrapper">
        <div className="table-headers">
          <SortableHeader text={t('settings.countries.countryLabel')} sortByText="name" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
          <SortableHeader text={t('settings.countries.maxWorkingDaysLabel')} sortByText="supervisor" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
          <SortableHeader text={t('settings.countries.taxPeriodLabel')} sortByText="tax-code" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
          <SortableHeader text={t('settings.countries.descriptionLabel')} sortByText="status" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
        </div>
        { 
          countries.map((country, i) => <div className="table-row" key={i}> 
            <div className="table-cell text">{t(`settings.countryCodes.${country.name}`)}</div>
            <div className="table-cell text">{country.maxWorkingDays}</div>
            <div className="table-cell text">{renderTaxPeriod(country.taxPeriodStart, country.taxPeriodEnd)}</div>
            <div className="table-cell text">{country.description || '-'}</div>
          </div>) 
        }
      </section>

      <section className="data-list-mobile show-small-screens">
        {
          countries.map((country, i) => <div className="list-item" key={i}>
            <div className="list-item-grid"> 
              <div className="item-cell">
                <div className="text color-text-light">{t('settings.countries.countryLabel')}</div>
                <div className="text">{t(`settings.countryCodes.${country.name}`)}</div>
              </div>
              <div className="item-cell">
                <div className="text color-text-light">{t('settings.countries.maxWorkingDaysLabel')}</div>
                <div className="text">{country.maxWorkingDays}</div>
              </div>
              <div className="item-cell">
                <div className="text color-text-light">{t('settings.countries.taxPeriodLabel')}</div>
                <div className="text">{renderTaxPeriod(country.taxPeriodStart, country.taxPeriodEnd)}</div>
              </div>
              <div className="item-cell">
                <div className="text color-text-light">{t('settings.countries.descriptionLabel')}</div>
                <div className="text">{country.description || '-'}</div>
              </div>
            </div>
          </div>)
        }
      </section>
      
    </div>
  );
}

export default CountriesTab
