import React, { useEffect } from 'react'
import './MainComponent.scss'
import { Switch, Route } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { useStateValue } from '../../state/state'
import SideMenu from '../SideMenu/SideMenu'
import DashboardPage from '../pages/DashboardPage/DashboardPage'
import ReportPage from '../pages/ReportPage/ReportPage'
import ProjectsPage from '../pages/ProjectsPage/ProjectsPage'
import UsersPage from '../pages/UsersPage/UsersPage'
import HelpPage from '../pages/HelpPage/HelpPage'
import SettingsPage from '../pages/SettingsPage/SettingsPage'
import LoginPage from '../pages/LoginPage/LoginPage'
import { getPermission } from '../../permissions'
import { getCookie } from '../../utils'
import { default as axios } from '../../axiosSettings'
import { useTranslation } from 'react-i18next'


function MainComponent() {
  const [{ userRole, userData }, dispatch] = useStateValue()
  const { i18n } = useTranslation()
  const canSeeDashboardPage = getPermission(userRole, 'canSeeDashboardPage')
  const canSeeReportPage = getPermission(userRole, 'canSeeReportPage')
  const canSeeProjectsPage = getPermission(userRole, 'canSeeProjectsPage')
  const canSeeUsersPage = getPermission(userRole, 'canSeeUsersPage')
  const canSeeHelpPage = getPermission(userRole, 'canSeeHelpPage')
  const canSeeSettingsPage = getPermission(userRole, 'canSeeSettingsPage')
  const userId = getCookie('userId')
  const token = getCookie('token')
  const language = getCookie('language')


  useEffect(() => {
    i18n.changeLanguage(language || userData.language || 'en')
  }, [language, userData])


  useEffect(() => {
    if (token) {
      axios.get(`api/settings`, {
        headers: {
          Authorization: `bearer ${token}`,
        }
      })
        .then(response => {
          dispatch({type: 'setData', field: 'settings', data: response.data[0]})
        })
    }
  }, [userId])


  useEffect(() => {
    checkTokenValidity()
  }, [])

  useEffect(() => {
    const token = getCookie('token')
    if (token) {
      axios.get(`api/users/${userId}`, {
        headers: {Authorization: `bearer ${token}`}
      })
        .then(response => {
          dispatch({type: 'setData', field: 'userRole', data: response.data.roles[0]})
          dispatch({type: 'setData', field: 'userData', data: response.data})
        })
    }
  }, [userId])



  const checkTokenValidity = () => {
    setInterval(
      () => {
        const token = getCookie('token')
        const refreshToken = getCookie('refreshToken')

        if (token) {
          return null
        }
        else if (!token && refreshToken) {
          axios.post(`api/token/refresh`, {
            refresh_token: refreshToken,
          })
            .then(response => {
              if (response.status === 200) {
                const tokenExpirationDate = new Date()
                tokenExpirationDate.setHours(tokenExpirationDate.getHours() + 1)
                // tokenExpirationDate.setSeconds(tokenExpirationDate.getSeconds() + 5)
                document.cookie = `token=${response.data.token}; path=/; expires=${tokenExpirationDate.toUTCString()}`
                document.cookie = `refreshToken=${response.data.refresh_token}; path=/`
              }
            })
        }
        else if (!token && !refreshToken) {
          document.cookie = 'userId=; Max-Age=-99999999;'
        }
      }, 1000
    )
  }

  if (!userId) {
    return <LoginPage />
  }

  return (
    <div className="main-component">
      <SideMenu />
      <div className="page">
        <Switch>
          { canSeeDashboardPage && <Route path="/dashboard" component={DashboardPage} /> }
          { canSeeReportPage && <Route path="/report" component={ReportPage} /> }
          { canSeeProjectsPage && <Route path="/projects" component={ProjectsPage} /> }
          { canSeeUsersPage && <Route path="/users" component={UsersPage} /> }
          { canSeeHelpPage && <Route path="/help" component={HelpPage} /> }
          { canSeeSettingsPage && <Route path="/settings/:settingsTab" component={SettingsPage} /> }
          <Route component={DashboardPage} />
        </Switch>
      </div>
    </div>
  )
}

export default withRouter(MainComponent)
