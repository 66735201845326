import React, { useState, useEffect } from 'react'
import './HelpSectionTab.scss'
import RichTextEditor from 'react-rte'
import { useStateValue } from '../../../../../state/state'
import { getCookie } from '../../../../../utils'
import { default as axios } from '../../../../../axiosSettings'
import { defaultHelpText } from '../../../../../config'
import Snackbar from '../../../../common/Snackbar/Snackbar'
import { useTranslation } from 'react-i18next'



const toolbarConfig = {
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'}
  ],
  BLOCK_TYPE_DROPDOWN: [
    {label: 'Normal', style: 'unstyled'},
    {label: 'Heading Large', style: 'header-one'},
    {label: 'Heading Medium', style: 'header-two'},
    {label: 'Heading Small', style: 'header-three'}
  ],
  BLOCK_TYPE_BUTTONS: [
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'}
  ]
}


function HelpSectionTab() {
  const { t } = useTranslation()
  const [{ settings }, dispatch] = useStateValue()
  const [helpContent, setHelpContent] = useState(RichTextEditor.createValueFromString('', 'html'))
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [successMessage, setSuccessMessage] = useState(null)
  const token = getCookie('token')


  useEffect(() => {
    setHelpContent(settings.helpText ? RichTextEditor.createValueFromString(settings.helpText, 'html') : RichTextEditor.createValueFromString(defaultHelpText, 'html'))
  }, [settings])


  useEffect(() => {
    setIsButtonDisabled(helpContent.toString('html') === settings.helpText)
  }, [helpContent])


  const handleHelpTextSave = () => {
    axios.patch(`api/settings/1`, {
      helpText: helpContent.toString('html')
    }, { 
      headers: { 
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/merge-patch+json',
      } 
    })
      .then(response => {
        dispatch({ type: 'setData', field: 'settings', data: response.data })
        setSuccessMessage(t('settings.helpSection.updateSuccessMessage'))
      })
  }
  

  const handleHelpTextCancel = () => {
    setHelpContent(settings.helpText ? RichTextEditor.createValueFromString(settings.helpText, 'html') : RichTextEditor.createValueFromString(defaultHelpText, 'html'))
  }

  
  return (
    <div className="help-section-tab">
      {
        settings.hasOwnProperty('helpText') && <RichTextEditor
          className="rich-text-editor"
          value={helpContent}
          toolbarConfig={toolbarConfig}
          onChange={value => setHelpContent(value)}
        />
      }
      
      <div className="buttons-wrapper">
        <button className="button button-secondary ripple-effect" onClick={handleHelpTextCancel}>{t('settings.helpSection.cancelButton')}</button>
        <button className={`button button-primary ripple-effect ripple-effect-lighter ${isButtonDisabled && 'button-primary-disabled'}`} onClick={handleHelpTextSave}>{t('settings.helpSection.saveButton')}</button>
      </div>

      {
        successMessage && <Snackbar type="success" text={successMessage} closeHandler={() => setSuccessMessage(null)} />
      }
    </div>
  );
}

export default HelpSectionTab;
