import React, { useState, useEffect } from 'react'
import './AllTrackingsComponent.scss'
import SortableHeader from '../../../../common/SortableHeader/SortableHeader'
import PopoverMenu from '../../../../common/PopoverMenu/PopoverMenu'
import editIcon from '../../../../../assets/edit-icon.svg'
import saveIcon from '../../../../../assets/save-icon.svg'
import editCloseIcon from '../../../../../assets/close-icon-black-lighter.svg'
import SelectInput from '../../../../common/SelectInput/SelectInput'
import CalendarInput from '../../../../common/CalendarInput/CalendarInput'
import { useStateValue } from '../../../../../state/state'
import { getPermission } from '../../../../../permissions'
import { getCookie } from '../../../../../utils'
import { default as axios } from '../../../../../axiosSettings'
import { ITEMS_PER_PAGE } from '../../../../../config'
import moment from 'moment'
import Snackbar from '../../../../common/Snackbar/Snackbar'
import { useTranslation } from 'react-i18next'


function AllTrackingsComponent({ 
  usersFilterOption, 
  usersFilterOptions, 
  projectsFilterOption, 
  projectsFilterOptions,
  countriesFilterOption,
  countriesFilterOptions,
  statusesFilterOption,
  startDate,
  endDate, 
  taxPeriodSelected 
}) {
  const { t } = useTranslation()

  const typesOptions = [
    { value: 1, label: t('dashboard.allTrackingsComponent.workingLabel') },
    { value: 2, label: t('dashboard.allTrackingsComponent.nonWorkingLabel') },
  ]
  const statusesOptions = [
    { value: 1, label: t('dashboard.allTrackingsComponent.openStatusLabel') },
    { value: 2, label: t('dashboard.allTrackingsComponent.confirmedStatusLabel') },
    { value: 3, label: t('dashboard.allTrackingsComponent.closedStatusLabel') },
  ]
  const limitedStatusesOptions = [
    { value: 1, label: t('dashboard.allTrackingsComponent.openStatusLabel') },
    { value: 2, label: t('dashboard.allTrackingsComponent.confirmedStatusLabel') },
  ]
  
  const renderTypeLabel = type => {
    if (type === 1) return <span className="label-working">{t('dashboard.allTrackingsComponent.workingLabel')}</span>
    if (type === 2) return <span className="label-non-working">{t('dashboard.allTrackingsComponent.nonWorkingLabel')}</span>
    return <>{type}</>
  }

  const [{ refreshTrackings }, dispatch] = useStateValue()
  const [searchQuery, setSearchQuery] = useState('')
  const [sortBy, setSortBy] = useState('date')
  const [sortDirection, setSortDirection] = useState('desc')
  const [editedRow, setEditedRow] = useState(null)
  const [trackings, setTrackings] = useState([])
  const [trackingsTotalCount, setTrackingsTotalCount] = useState(null)
  const [limit, setLimit] = useState(ITEMS_PER_PAGE)
  const [offset, setOffset] = useState(0)
  const [successMessaage, setSuccessMessage] = useState(null)
  const token = getCookie('token')
  const userId = getCookie('userId')



  useEffect(() => {
    getTrackings()
  }, [sortBy, sortDirection, searchQuery, usersFilterOption, projectsFilterOption, countriesFilterOption, statusesFilterOption, startDate, endDate, taxPeriodSelected])


  useEffect(() => {
    if (refreshTrackings) {
      getTrackings()
      dispatch({ type: 'setData', field: 'refreshTrackings', data: false })
    }
  }, [refreshTrackings])

  
  const getTrackings = () => {
    if (!usersFilterOption) return

    let url = `api/trackings?limit=${limit}&offset=0&sort_by=${sortBy}&sort_direction=${sortDirection}&search=${searchQuery}`
    if (usersFilterOption && usersFilterOption.value !== 'all') url += `&user=${usersFilterOption.value}`
    if (projectsFilterOption && projectsFilterOption.value !== 'all') url += `&project=${projectsFilterOption.value}`
    if (countriesFilterOption && countriesFilterOption.value !== 'all') url += `&country=${countriesFilterOption.value}`
    if (statusesFilterOption && statusesFilterOption.value !== 'all') url += `&status=${statusesFilterOption.value}`
    if (startDate) url += `&start_date=${startDate.split('T')[0]}`
    if (endDate) url += `&end_date=${endDate.split('T')[0]}`
    if (taxPeriodSelected) url += `&current_tax_period=1`

    axios.get(url, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        setTrackings(response.data.items)
        setTrackingsTotalCount(response.data.totalCount)
      })
  }


  const getMoreTrackings = () => {
    const newOffset = offset + ITEMS_PER_PAGE
    let url = `api/trackings?sort_by=${sortBy}&sort_direction=${sortDirection}&limit=${ITEMS_PER_PAGE}&offset=${newOffset}&search=${searchQuery}`
    if (usersFilterOption && usersFilterOption.value !== 'all') url += `&user=${usersFilterOption.value}`
    if (projectsFilterOption && projectsFilterOption.value !== 'all') url += `&project=${projectsFilterOption.value}`
    if (countriesFilterOption && countriesFilterOption.value !== 'all') url += `&country=${countriesFilterOption.value}`
    if (statusesFilterOption && statusesFilterOption.value !== 'all') url += `&status=${statusesFilterOption.value}`
    if (startDate) url += `&start_date=${startDate.split('T')[0]}`
    if (endDate) url += `&end_date=${endDate.split('T')[0]}`
    if (taxPeriodSelected) url += `&current_tax_period=1`
    
    axios.get(url, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        setOffset(newOffset)
        setTrackings(trackings => [...trackings, ...response.data.items])
        setLimit(limit + ITEMS_PER_PAGE) 
      })
  }


  const handleSaveChanges = () => {
    const changes = {
      date: editedRow.date,
      user: `api/users/${editedRow.user.id}`,
      type: editedRow.type,
      country: `api/countries/${editedRow.country.id}`,
      project: `api/projects/${editedRow.project.id}`,
      status: editedRow.status
    }

    axios.patch(`api/trackings/${editedRow.id}`, changes, {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/merge-patch+json',
      }
    })
      .then(() => {
        setEditedRow(null)
        dispatch({ type: 'setData', field: 'refreshTrackings', data: true })
        setSuccessMessage(t('dashboard.allTrackingsComponent.editConfirmMessage'))
      })
  }


  const handleConfirmTracking = trackingId => {
    axios.patch(`api/trackings/${trackingId}`, {
      status: 2,
    }, {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/merge-patch+json',
      }
    })
      .then(() => {
        getTrackings()
        dispatch({ type: 'setData', field: 'refreshTrackings', data: true })
      })
  }


  const handleDeleteTracking = trackingId => {
    axios.patch(`api/trackings/${trackingId}`, {
      deleted: true,
    }, {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/merge-patch+json',
      }
    })
      .then(() => {
        dispatch({ type: 'setData', field: 'refreshTrackings', data: true })
        setSuccessMessage(t('dashboard.allTrackingsComponent.deleteConfirmMessage'))
      })
  }

  
  const TableRow = ({ tracking, editedRow, setEditedRow }) => {
    const [{ userRole }] = useStateValue()
    const canEditTracking = getPermission(userRole, 'canEditTracking')
    const canDeleteTracking = getPermission(userRole, 'canDeleteTracking')
    const canConfirmTracking = getPermission(userRole, 'canConfirmTracking')
    const canCloseTracking = getPermission(userRole, 'canCloseTracking')

    return (
      <div className={`table-row ${editedRow && editedRow.id  === tracking.id && 'small-vertical-padding'}`}> 
        {
          editedRow && editedRow.id === tracking.id 
            ? <div className="table-cell text">
                <CalendarInput
                  value={editedRow.date} 
                  placeholder={t('dashboard.allTrackingsComponent.calendarPlaceholder')}
                  changeHandler={day => {
                    const newEditedRow = { ...editedRow, date: day }
                    setEditedRow(newEditedRow)
                  }}
                />
              </div>
            : <div className="table-cell text">{moment(tracking.date).format('YYYY-MM-DD')}</div>
        }
        {
          usersFilterOptions.length && editedRow && editedRow && editedRow.id === tracking.id
            ? <div className="table-cell text">
                <SelectInput 
                  options={usersFilterOptions.slice(1)}
                  selectedOption={usersFilterOptions.find(option => option.value === editedRow.user.id)}
                  changeHandler={selectedOption => {
                    const newEditedRow = { ...editedRow, user: { id: selectedOption.value, name: selectedOption.label } }
                    setEditedRow(newEditedRow)
                  }}
                  width='100%'
                  height='30px'
                />
              </div>
            : <div className="table-cell text centered">{tracking.user && tracking.user.name}</div>
        }
        {
          editedRow && editedRow.id === tracking.id 
            ? <div className="table-cell text">
                <SelectInput 
                  options={typesOptions}
                  selectedOption={typesOptions.find(option => option.value === editedRow.type)}
                  changeHandler={option => {
                    const newEditedRow = { ...editedRow, type: option.value }
                    setEditedRow(newEditedRow)
                  }}
                  width='100%'
                  height='30px'
                />
              </div>
            : <div className="table-cell text">{renderTypeLabel(tracking.type)}</div>
        }
        {
          editedRow && editedRow.id === tracking.id 
            ? <div className="table-cell text">
                <SelectInput 
                  options={countriesFilterOptions.slice(1)}
                  selectedOption={countriesFilterOptions.find(option => option.value === editedRow.country.id)}
                  changeHandler={selectedOption => {
                    const newEditedRow = { ...editedRow, country: { id: selectedOption.value, name: selectedOption.label } }
                    setEditedRow(newEditedRow)
                  }}
                  width='100%'
                  height='30px'
                />
              </div>
            : <div className="table-cell text">{tracking.country && t(`settings.countryCodes.${tracking.country.name}`)}</div>
        }
        {
          editedRow && editedRow.id === tracking.id 
            ? <div className="table-cell text">
                <SelectInput 
                  options={projectsFilterOptions.slice(1)}
                  selectedOption={projectsFilterOptions.find(option => option.value === editedRow.project.id)}
                  changeHandler={selectedOption => {
                    const newEditedRow = { ...editedRow, project: { id: selectedOption.value, name: selectedOption.label } }
                    setEditedRow(newEditedRow)
                  }}
                  width='100%'
                  height='30px'
                />
              </div>
            : <div className="table-cell text">{tracking.project && tracking.project.name}</div>
        }

        {
          editedRow && editedRow.id === tracking.id
            ? <div className="table-cell text">
                <SelectInput 
                  options={canCloseTracking ? statusesOptions : limitedStatusesOptions}
                  selectedOption={statusesOptions.find(option => option.value === editedRow.status)}
                  changeHandler={option => {
                    const newEditedRow = { ...editedRow, status: option.value }
                    setEditedRow(newEditedRow)
                  }}
                  width='100%'
                  height='30px'
                />
              </div>
            : <div className="table-cell text centered">{renderStatus(tracking.status)}</div>
        }
        <div className="table-cell last-cell text">
        { canEditTracking && (!editedRow || !editedRow.id) && <img className="edit-row-icon" src={editIcon} alt="edit row" onClick={() => setEditedRow(tracking)} /> }
          {
            editedRow && editedRow.id === tracking.id && <>
              <img className="save-row-icon" src={saveIcon} alt="save" onClick={handleSaveChanges} />
              <img className="cancel-row-icon" src={editCloseIcon} alt="cancel" onClick={() => setEditedRow(null)} />
            </>
          }
          <PopoverMenu>
            { canDeleteTracking && <div className="popover-menu-option" onClick={() => handleDeleteTracking(tracking.id)}>{t('dashboard.allTrackingsComponent.deleteOption')}</div> }
            { canConfirmTracking && tracking.status !== 2 && <div className="popover-menu-option" onClick={() => handleConfirmTracking(tracking.id)}>{t('dashboard.allTrackingsComponent.confirmOption')}</div> }
          </PopoverMenu>
        </div>
      </div>
    )
  }


  const renderStatus = status => {
    if (status === 1) return t('dashboard.allTrackingsComponent.openStatusLabel')
    if (status === 2) return t('dashboard.allTrackingsComponent.confirmedStatusLabel')
    if (status === 3) return t('dashboard.allTrackingsComponent.closedStatusLabel')
    return status
  }


  return (
    <div className="all-trackings-component">
      <header>
        <div className="text bold color-text-light">{t('dashboard.allTrackingsComponent.title')}</div>
        <input type="text" className="search-bar" placeholder={t('dashboard.allTrackingsComponent.searchInputPlaceholder')} value={searchQuery} onChange={e => setSearchQuery(e.target.value) } />
      </header>

      <div className="table-headers">
        <SortableHeader text={t('dashboard.allTrackingsComponent.dateHeader')} sortByText="date" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
        <SortableHeader text={t('dashboard.allTrackingsComponent.nameHeader')} sortByText="user" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
        <SortableHeader text={t('dashboard.allTrackingsComponent.typeHeader')} sortByText="type" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
        <SortableHeader text={t('dashboard.allTrackingsComponent.countryHeader')} sortByText="country" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
        <SortableHeader text={t('dashboard.allTrackingsComponent.projectHeader')} sortByText="project" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
        <SortableHeader text={t('dashboard.allTrackingsComponent.statusHeader')} sortByText="status" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
        <div />
      </div>
      { 
        trackings.map((tracking, i) => <TableRow key={i} tracking={tracking} editedRow={editedRow} setEditedRow={setEditedRow} />) 
      }

      <section className="table-data">
        <div className="label color-text-light">{t('dashboard.allTrackingsComponent.showing')} {trackings.length ? 1 : 0}-{trackings.length} {t('dashboard.allTrackingsComponent.of')} {trackingsTotalCount} {t('dashboard.allTrackingsComponent.entries')}</div>
        {
          trackingsTotalCount > limit && <button className="button button-secondary ripple-effect" onClick={() => {
            getMoreTrackings()
          }}>{t('dashboard.allTrackingsComponent.showMoreButton')}</button>
        }
        <div />
      </section>

      {
        successMessaage && <Snackbar type="success" text={successMessaage} closeHandler={() => setSuccessMessage(null)} />
      }
    </div>
  );
}

export default AllTrackingsComponent;
