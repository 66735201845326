import React from 'react';
import './App.css';
import './GlobalStyles.scss';
import MainComponent from './components/MainComponent/MainComponent';
import { BrowserRouter as Router } from "react-router-dom";
import { StateProvider } from './state/state';
import { initialState } from './state/initialState';
import { reducer } from './state/reducer';
import { ScrollToTop } from './utils';


function App() {
  return (
    <div className="App">
      <StateProvider initialState={initialState} reducer={reducer}>
        <Router>
          <ScrollToTop />
          <MainComponent />
        </Router>
      </StateProvider>
    </div>
  );
}

export default App