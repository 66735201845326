import React, { useState, useEffect } from 'react'
import './CountriesComponent.scss'
import { useStateValue } from '../../../../../state/state'
import { getCookie } from '../../../../../utils'
import { default as axios } from '../../../../../axiosSettings'
import { useTranslation } from 'react-i18next'


function CountriesComponent({
  usersFilterOption,
  projectsFilterOption,
  countriesFilterOption,
  statusesFilterOption,
  startDate,
  endDate,
  taxPeriodSelected,
}) {
  const [{ refreshTrackings }, dispatch] = useStateValue()
  const [loggedCountries, setLoggedCountries] = useState(null)
  const token = getCookie('token')
  const { t } = useTranslation()


  useEffect(() => {
    getCountriesCount()
  }, [
    usersFilterOption,
    projectsFilterOption,
    countriesFilterOption,
    statusesFilterOption,
    startDate,
    endDate,
    taxPeriodSelected,
  ])


  useEffect(() => {
    getCountriesCount()
    dispatch({ type: 'setData', field: 'refreshTrackings', data: false })
  }, [refreshTrackings])


  const getCountriesCount = () => {
    if (!usersFilterOption) return

    let url = `api/countries/logged-countries?`
    if (usersFilterOption && usersFilterOption.value !== 'all') url += `user=${usersFilterOption.value}&`
    if (projectsFilterOption && projectsFilterOption.value !== 'all') url += `project=${projectsFilterOption.value}&`
    if (countriesFilterOption && countriesFilterOption.value !== 'all') url += `country=${countriesFilterOption.value}&`
    if (statusesFilterOption && statusesFilterOption.value !== 'all') url += `status=${statusesFilterOption.value}&`
    if (startDate) url += `start_date=${startDate.split('T')[0]}&`
    if (endDate) url += `end_date=${endDate.split('T')[0]}&`
    if (taxPeriodSelected) url += `current_tax_period=1&`

    axios.get(url, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        setLoggedCountries(response.data)
      })
  }


  return (
    <div className="countries-component">
      <h2>{loggedCountries ? loggedCountries.count : 0}</h2>
      <div className="text color-text-light">{t('dashboard.countriesCountComponent.countries')}</div>
    </div>
  )
}

export default CountriesComponent
