import React from 'react';
import './AllCountriesModal.scss';
import CountryBar from '../../../../../common/CountryBar/CountryBar';
import CountryChart from '../../../../../common/CountryChart/CountryChart';
import { useStateValue } from '../../../../../../state/state'
import { useTranslation } from 'react-i18next'


function AllCountriesModal({ 
  trackings, 
  usersFilterOption,
  projectsFilterOption,
  countriesFilterOption,
  statusesFilterOption,
  startDate,
  endDate,
  taxPeriodSelected,
  closeModalHandler }) {
  const [{ userRole }] = useStateValue()
  const { t } = useTranslation()


  return (
    <div className="all-countries-modal">
      <div className="modal-content">
        <h2>{t('dashboard.trackingByCountriesComponent.modalTitle')}</h2>
        <div className="bars-wrapper">
          {
            trackings
              .sort((a, b) => a.maxDays - a.workingDays < b.maxDays - b.workingDays ? -1 : 1)  
              .map((tracking, i) => <div key={i} className="country-bar-wrapper">
                {
                userRole && userRole !== 'ROLE_USER' && usersFilterOption && usersFilterOption.value === 'all'
                  ? <CountryChart tracking={tracking} filterStartDate={startDate} filterEndDate={endDate} />
                  : <CountryBar tracking={tracking} filterStartDate={startDate} filterEndDate={endDate} />
              }
            </div>)
          }
        </div>
        <span className="button button-secondary" onClick={closeModalHandler}>{t('dashboard.trackingByCountriesComponent.closeButtonText')}</span>
      </div>
    </div>
  );
}

export default AllCountriesModal;
