import React, { useState, useEffect } from 'react'
import './EditProjectModal.scss'
import { useStateValue } from '../../../../state/state'
import SelectInput from '../../../common/SelectInput/SelectInput'
import CalendarInput from '../../../common/CalendarInput/CalendarInput'
import MultichoiceSelect from '../../../common/MultichoiceSelect/MultichoiceSelect'
import Snackbar from '../../../common/Snackbar/Snackbar'
import { getCookie } from '../../../../utils'
import { default as axios } from '../../../../axiosSettings'
import { getPermission } from '../../../../permissions'
import { useTranslation } from 'react-i18next'


function EditProjectModal({ projectData, cancelHandler, closeAfterSuccessHandler }) {
  const { t } = useTranslation()

  const statusOptions = [
    { value: 0, label: t('editProject.inactiveLabel') },
    { value: 1, label: t('editProject.activeLabel') },
    { value: 2, label: t('editProject.archivedLabel') },
  ]
  
  const limitedStatusOptions = [
    { value: 0, label: t('editProject.inactiveLabel') },
    { value: 1, label: t('editProject.activeLabel') },
  ]

  const [{ userRole }] = useStateValue()
  const [projectName, setProjectName] = useState(projectData.name || '')
  const [projectNameError, setProjectNameError] = useState(false)
  const [endDate, setEndDate] = useState(projectData.endDate || null)
  const [endDateError, setEndDateError] = useState(false)
  const [taxCode, setTaxCode] = useState(projectData.taxCode || '')
  const [supervisorsOptions, setSupervisorsOptions] = useState([])
  const [supervisors, setSupervisors] = useState([
    { value: projectData.mainSupervisor && projectData.mainSupervisor.id, label: projectData.mainSupervisor && projectData.mainSupervisor.name }, 
    ...projectData.supervisors.map(supervisor => { return { value: supervisor.id, label: supervisor.name }})
  ])
  const [supervisorsError, setSupervisorsError] = useState(false)
  const [status, setStatus] = useState(projectData.status ? statusOptions.find(option => option.value === projectData.status) : { value: 'active', label: t('editProject.archivedLabel') })
  const [validationFailedError, setValidationFailedError] = useState(null)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const token = getCookie('token')
  const userId = getCookie('userId')
  const canArchiveAndReactivateProject = getPermission(userRole, 'canArchiveAndReactivateProject')


  useEffect(() => {
    setIsButtonDisabled(
      projectName === projectData.name &&
      endDate === projectData.endDate &&
      taxCode === projectData.taxCode &&
      supervisors === projectData.supervisors &&
      status === projectData.status
    )
  }, [projectName, endDate, taxCode, supervisors, status])


  useEffect(() => {
    axios.get(`api/users/all?roles[]=ROLE_PM&roles[]=ROLE_HR`, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        const suervisorsList = response.data.map(user => { return {value: user.id, label: user.name}})
        setSupervisorsOptions(suervisorsList)
      })
  }, [])


  const validateFormData = () => {
    const projectNameError = !projectName
    const endDateError = !endDate
    const supervisorsError = !supervisors || !supervisors.length

    if (
        !projectNameError
        && !endDateError
        && !supervisorsError
      ) {
      saveProjectChanges()
      setValidationFailedError(null)
    } else {
      setValidationFailedError(t('editProject.validationErrorMessage'))
    }

    setProjectNameError(projectNameError)
    setEndDateError(endDateError)
    setSupervisorsError(supervisorsError)
  }
  

  const saveProjectChanges = () => {
    const changes = {
      name: projectName,
      endDate: endDate,
      taxCode: taxCode,
      mainSupervisor: `api/users/${supervisors[0].value}`,
      supervisors: supervisors.splice(1).map(supervisor => `api/users/${supervisor.value}`),
      status: status.value,
    }

    axios.patch(`api/projects/${projectData.id}`, changes, {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/merge-patch+json',
      }
    })
      .then(() => {
        closeAfterSuccessHandler()
      })
  }


  return (
    <div className="edit-project-modal">
      <div className="modal-content">
        <h2>{t('editProject.title')}</h2>

        <div className="inputs">

          <div className="input-wrapper">
            <div className="label">{t('newProject.nameLabel')}</div>
            <input className={`text-input w-250 ${projectNameError && 'border-red'}`} type="text" placeholder={t('newProject.namePlaceholder')} value={projectName} onChange={e => setProjectName(e.target.value)} />
          </div>

          <div className="input-wrapper">
            <div className="label">{t('newProject.endDateLabel')}</div>
            <CalendarInput 
              value={endDate} 
              placeholder={t('newProject.endDatePlaceholder')} 
              changeHandler={day => setEndDate(day)} 
              error={endDateError}
            />
          </div>

          <div className="input-wrapper">
            <div className="label">{t('newProject.taxCodeLabel')}</div>
            <input className="text-input w-250" type="text" placeholder={t('newProject.taxCodePlaceholder')} value={taxCode} onChange={e => setTaxCode(e.target.value)} />
          </div>

          <div className="input-wrapper">
            <div className="label">{t('newProject.supervisorLabel')}</div>
              <MultichoiceSelect
                placeholder={t('newProject.supervisorPlaceholder')}
                options={supervisorsOptions}
                selectedOptions={supervisors}
                changeHandler={options => {
                  if ( (supervisors && supervisors.find(supervisor => supervisor.value == userId)) && (!options || !options.find(supervisor => supervisor.value == userId)) ) {
                    setSupervisors(supervisors)
                    setValidationFailedError(t('editProject.removingYourselfWarning'))
                  } else {
                    setSupervisors(options)
                  }
                }}
                width={250}
                error={supervisorsError}
              />
          </div>

          <div className="input-wrapper">
            <div className="label">{t('editProject.statusLabel')}</div>
            <SelectInput
              options={canArchiveAndReactivateProject ? statusOptions : limitedStatusOptions}
              selectedOption={status}
              changeHandler={option => setStatus(option)}
              width='200px'
            />
          </div>

        </div>

        <div className="input-wrapper buttons-wrapper">
          <button className="button button-secondary ripple-effect" onClick={cancelHandler}>{t('newProject.cancelButton')}</button>
          <button className={`button button-primary ripple-effect ripple-effect-lighter ${isButtonDisabled && 'button-primary-disabled'}`} onClick={validateFormData}>{t('editProject.saveChangesButton')}</button>
        </div>
      </div>

      {
        validationFailedError && <Snackbar type="error" text={validationFailedError} closeHandler={() => setValidationFailedError(null)} />
      }
    </div>
  );
}

export default EditProjectModal
