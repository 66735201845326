import React, { useState, useEffect } from 'react'
import './UserSettingsTab.scss'
import { useStateValue } from '../../../../../state/state'
import { validateEmail, validatePassword, getCookie } from '../../../../../utils'
import Snackbar from '../../../../common/Snackbar/Snackbar'
import { default as axios } from '../../../../../axiosSettings'
import SelectInput from '../../../../common/SelectInput/SelectInput'
import { useTranslation } from 'react-i18next'


function UserSettingsTab() {
  const { t } = useTranslation()

  const languageOptions = [
    { value: 'en', label: t('settings.userSettings.englishLabel') },
    { value: 'de', label: t('settings.userSettings.germanLabel') },
  ]

  const [{ userData }, dispatch] = useStateValue()
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [password, setPassword] = useState('')
  const [language, setLanguage] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [validationFailedError, setValidationFailedError] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  const token = getCookie('token')
  const { i18n } = useTranslation()


  useEffect(() => {
    setName(userData.name || '')
    setEmail(userData.email || '')
    setLanguage(userData.language || languageOptions[0].value)
  }, [userData])


  useEffect(() => {
    setIsButtonDisabled(
      name === userData.name &&
      email === userData.email &&
      password === '' &&
      language === userData.language
    )
  }, [userData, name, email, password, language])


  const saveUserChanges = () => {
    const changes = {
      name: name,
      email: email,
      language: language,
    }

    if (password) {
      changes.password = password
    }

    axios.patch(`api/users/${userData.id}`, changes, {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/merge-patch+json',
      }
    })
      .then(() => {
        setSuccessMessage(t('settings.userSettings.saveSuccessMessage'))
        getUserData()
        i18n.changeLanguage(language)
        const tokenExpirationDate = new Date()
        tokenExpirationDate.setFullYear(tokenExpirationDate.getFullYear() + 1)
        document.cookie = `language=${language}; path=/; expires=${tokenExpirationDate.toUTCString()}`
      })
  }

  const getUserData = () => {
    axios.get(`api/users/${userData.id}`, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        dispatch({ type: 'setData', field: 'userData', data: response.data })
      })
  }

  const validateFormData = () => {
    const nameError = !name
    const emailError = !validateEmail(email)
    const passwordError = password && !validatePassword(password)

    if (
        !nameError
        && !emailError
        && !passwordError
      ) {
      saveUserChanges()
      setValidationFailedError(null)
    } else {
      setValidationFailedError(t('settings.userSettings.saveErrorMessage'))
    }

    setNameError(nameError)
    setEmailError(emailError)
    setPasswordError(passwordError)
  }


  const handleCancelChanges = () => {
    setName(userData.name || '')
    setEmail(userData.email || '')
    setPassword('')
  }


  return (
    <div className="user-settings-tab">
      <div className="input-wrapper">
        <div className="label">{t('settings.userSettings.nameLabel')}</div>
        <input className={`text-input w-200 ${nameError && 'border-red'}`} type="text" placeholder={t('settings.userSettings.namePlaceholder')} value={name} onChange={e => setName(e.target.value)} />
      </div>

      <div className="input-wrapper">
        <div className="label">{t('settings.userSettings.emailLabel')}</div>
        <input className={`text-input w-250 ${emailError && 'border-red'}`} type="text" placeholder={t('settings.userSettings.emailPlaceholber')} value={email} onChange={e => setEmail(e.target.value)} />
      </div>

      <div className="input-wrapper">
        <div className="label">{t('settings.userSettings.newPasswordLabel')}</div>
        <input className={`text-input w-250 ${passwordError && 'border-red'}`} type="password" placeholder={t('settings.userSettings.newPasswordPlaceholder')} value={password} onChange={e => setPassword(e.target.value)} />
        <div className="help-text">{t('settings.userSettings.passwordRequirementsPart1')} <br/>{t('settings.userSettings.passwordRequirementsPart2')}</div>
      </div>

      <div className="input-wrapper w-200">
        <div className="label">{t('settings.userSettings.languageLabel')}</div>
        <SelectInput 
          options={languageOptions}
          selectedOption={languageOptions.find(option => option.value === language)}
          changeHandler={selectedOption => {
            setLanguage(selectedOption.value)
          }}
          height='50px'
        />
      </div>
      
      <div className="input-wrapper buttons-wrapper">
        <button className="button button-secondary ripple-effect" onClick={handleCancelChanges}>{t('settings.userSettings.cancelButton')}</button>
        <button className={`button button-primary ripple-effect ripple-effect-lighter ${isButtonDisabled && 'button-primary-disabled'}`} onClick={validateFormData}>{t('settings.userSettings.saveButton')}</button>
      </div>
      
      {
        validationFailedError && <Snackbar type="error" text={validationFailedError} closeHandler={() => setValidationFailedError(null)} />
      }
      {
        successMessage && <Snackbar type="success" text={successMessage} closeHandler={() => setSuccessMessage(null)} />
      }
    </div>
  )
}

export default UserSettingsTab
