import React from 'react'
import './NonWorkingDaysComponent.scss'
import { useTranslation } from 'react-i18next'


function NonWorkingDaysComponent({ trackingsSummary }) {
  const { t } = useTranslation()


  return (
    <div className="non-working-days-component">
      <div className="color-bar" />
      <h2 className="color-nonworkingday-default">{trackingsSummary.non_working_days_count || 0}</h2>
      <div className="text color-text-light">{t('dashboard.nonWorkingDaysComponent.nonWorkingDays')}</div>
    </div>
  )
}

export default NonWorkingDaysComponent
