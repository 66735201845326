import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import './LoginPage.scss'
import loginPageLogo from '../../../assets/login-page-logo.svg'
import Snackbar from '../../common/Snackbar/Snackbar'
import { useStateValue } from '../../../state/state'
import { default as axios } from '../../../axiosSettings'
import { useTranslation } from 'react-i18next'


function LoginPage(props) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginWarning, setLoginWarning] = useState(null)
  const [, dispatch] = useStateValue()
  const { t } = useTranslation()

  const handleLogin = () => {

    axios.post(`api/login`, {
      email: email,
      password: password,
    })
      .then(response => {
        if (response.status === 200) {
          const tokenExpirationDate = new Date()
          tokenExpirationDate.setHours(tokenExpirationDate.getHours() + 1)
          // tokenExpirationDate.setSeconds(tokenExpirationDate.getSeconds() + 5)
          document.cookie = `token=${response.data.token}; path=/; expires=${tokenExpirationDate.toUTCString()}`
          document.cookie = `refreshToken=${response.data.refresh_token}; path=/`
          document.cookie = `userId=${response.data.user.id}; path=/`
          dispatch({ type: 'setData', field: 'userRole', data: response.data.user.roles[0] })
          dispatch({ type: 'setData', field: 'userData', data: response.data.user })
          props.history.push('/dashboard')
        } else {
          setLoginWarning(t('loginPage.loginFailedMessage'))
        }
      })
      .catch(error => {
        setLoginWarning(t('loginPage.loginFailedMessage'))
      })
  }

  return (
    <div className="login-page">
      <div className="login-box">
        <div className="header-line">
          <h2>{t('loginPage.header')}</h2>
          <img src={loginPageLogo} alt="logo" />
        </div>
        <div className="input-wrapper">
          <input className="text-input w-250" 
            type="text" 
            placeholder={t('loginPage.emailPlaceholder')}
            value={email} 
            onChange={e => setEmail(e.target.value)} 
            onKeyDown={e => e.key === 'Enter' && handleLogin()} 
          />
        </div>
        <div className="input-wrapper">
          <input className="text-input w-250" 
            type="password" 
            placeholder={t('loginPage.passwordPlaceholder')}
            value={password} 
            onChange={e => setPassword(e.target.value)} 
            onKeyDown={e => e.key === 'Enter' && handleLogin()} 
          />
        </div>
        <div className="buttons-wrapper">
          <button className="button button-primary ripple-effect ripple-effect-lighter" onClick={handleLogin}>{t('loginPage.loginButtonText')}</button>
        </div>
      </div>
      {
        loginWarning && <Snackbar type="error" text={loginWarning} closeHandler={() => setLoginWarning(null)} />
      }
    </div>
  );
}

export default withRouter(LoginPage)
