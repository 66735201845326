import React from 'react';
import './SortableHeader.scss';
import sortArrowUp from '../../../assets/sort-arrow-up.svg';
import sortArrowDown from '../../../assets/sort-arrow-down.svg';


function SortableHeader({ text, sortByText, currentSortBy, currenctSortDirection, changeSortByHandler, changeSortDirectionHandler }) {
  return (
    <div className={`sortable-header text bold ${currentSortBy === sortByText && 'header-selected'}`}>
      <span onClick={() => {
        currentSortBy === sortByText
          ? changeSortDirectionHandler(currenctSortDirection === 'asc' ? 'desc' : 'asc')
          : changeSortDirectionHandler('asc')
        changeSortByHandler(sortByText)
      }}>
        <img src={sortArrowUp} alt="" className={`sort-arrow ${currentSortBy === sortByText && currenctSortDirection === 'desc' ? 'sort-arrow-selected' : ''}`} />
        <img src={sortArrowDown} alt="" className={`sort-arrow ${currentSortBy === sortByText && currenctSortDirection === 'asc' ? 'sort-arrow-selected' : ''}`} />
        <span className="header-text">{text}</span>
      </span>
    </div>
  )
}

export default SortableHeader;