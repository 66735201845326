import React, { useState } from 'react'
import './CountryChart.scss'
import { Chart } from 'react-charts'
import moment from 'moment'
import { useTranslation } from 'react-i18next'


function CountryChart({ tracking, filterStartDate, filterEndDate }) {
  const { t } = useTranslation()
  const [{ activeSeriesIndex, activeDatumIndex }, setState] = useState({
    activeSeriesIndex: -1,
    activeDatumIndex: -1
  })

  
  const defs = (
    <defs>
      <linearGradient id="0" x1="0" x2="0" y1="1" y2="0">
        <stop offset="0%" stopColor="#0375B4" />
        <stop offset="100%" stopColor="#0375B4" />
      </linearGradient>
      <linearGradient id="1" x1="0" x2="0" y1="1" y2="0">
        <stop offset="0%" stopColor="rgba(3, 117, 180, 0.5)" />
        <stop offset="100%" stopColor="rgba(3, 117, 180, 0.5)" />
      </linearGradient>
    </defs>
  )

  const getSeriesStyle = React.useCallback(
    series => ({
      color: `url(#${series.index % 4})`,
      opacity:
        activeSeriesIndex > -1
          ? series.index === activeSeriesIndex
            ? 1
            : 0.3
          : 1
    }),
    [activeSeriesIndex]
  )
  const getDatumStyle = React.useCallback(
    datum => ({
      r:
        activeDatumIndex === datum.index &&
        activeSeriesIndex === datum.seriesIndex
          ? 4
          : activeDatumIndex === datum.index
          ? 5
          : datum.series.index === activeSeriesIndex
          ? 3
          : datum.otherHovered
          ? 2
          : 2
    }),
    [activeDatumIndex, activeSeriesIndex]
  )
  const onFocus = React.useCallback(
    focused =>
      setState({
        activeSeriesIndex: focused ? focused.series.id : -1,
        activeDatumIndex: focused ? focused.index : -1
      }),
    [setState]
  )
  
  if (!tracking) return null

  const startDate = (filterStartDate && filterStartDate.split('T')[0]) || tracking.tax_period_start
  const endDate = (filterEndDate && filterEndDate.split('T')[0]) || tracking.tax_period_end
  const momentStartDate = moment(startDate)
  const momentEndDate = moment(endDate)
  let allDates = []
  let date = momentStartDate
  while (date.diff(momentEndDate) < 1) {
    allDates.push(date.format('YYYY-MM-DD'))
    date = date.add(1, 'days')
  }

  const workingDaysData = tracking.working_days_date 
    ? allDates.map(date => {
      const foundDate = tracking.working_days_date[0].find(day => day.date === date)
      return {
        x: moment(date).format('x'),
        y: foundDate ? foundDate.workingDays : 0,
      }
    })
    : [{x: 0, y: 100}]
  const nonWorkingDaysData = tracking.non_working_days_date 
    ? allDates.map(date => {
      const foundDate = tracking.non_working_days_date[0].find(day => day.date === date)
      return {
        x: moment(date).format('x'),
        y: foundDate ? foundDate.nonworkingDays : 0,
      }
    })
    : [{x: 0, y: 100}]

  const data = [
    {
      label: 'Working Days',
      data: workingDaysData
    },
    {
      label: 'Non Working Days',
      data: nonWorkingDaysData
    }
  ]

  const axes = [
    { primary: true, type: 'utc', position: 'bottom' },
    { type: 'linear', position: 'left' }
  ]


  return (
    <div className="country-chart">
      <div className="country-name">{t(`settings.countryCodes.${tracking.name}`)}</div>
      <Chart 
        data={data}
        axes={axes}
        getSeriesStyle={getSeriesStyle}
        getDatumStyle={getDatumStyle}
        onFocus={onFocus}
        renderSVG={() => defs}
      />
      <div className="chart-info">
        <span className="chart-info-group">
          <span className="dot working-day-dot" />
          <span>{t('dashboard.trackingByCountriesComponent.workingDayLabel')}</span>
        </span>
        <span className="chart-info-group">
          <span className="dot non-working-day-dot" />
          <span>{t('dashboard.trackingByCountriesComponent.nonWorkingDayLabel')}</span>
        </span>
      </div>
    </div>
  );
}

export default CountryChart
