import React from 'react';
import './QuickLogModal.scss';
import QuickLogComponent from '../grid-elements/QuickLogComponent/QuickLogComponent';


function QuickLogModal({ cancelHandler }) {
  return (
    <div className="quick-log-modal show-small-screens">
      <QuickLogComponent cancelHandler={cancelHandler} />
    </div>
  );
}

export default QuickLogModal;
