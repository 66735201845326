import React, { useState, useEffect } from 'react'
import './QuickLogComponent.scss'
import DayPicker, { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import Snackbar from '../../../../common/Snackbar/Snackbar'
import QuickLogSelect from '../../../../common/QuickLogSelect/QuickLogSelect'
import { getCookie } from '../../../../../utils'
import { default as axios } from '../../../../../axiosSettings'
import { useStateValue } from '../../../../../state/state'
import moment from 'moment'
import 'moment/locale/de'
import { useTranslation } from 'react-i18next'
import MomentLocaleUtils from 'react-day-picker/moment'


function QuickLogComponent({ cancelHandler }) {
  const [{ refreshTrackings }, dispatch] = useStateValue()
  const [calendarTrackingsStartDate, setCalendarTrackingsStartDate] = useState(moment().startOf('month').add(-1, 'months').format('YYYY-MM-DD'))
  const [calendarTrackingsEndDate, setCalendarTrackingsEndDate] = useState(moment().endOf('month').add(1, 'months').format('YYYY-MM-DD'))
  const [calendarTrackings, setCalendarTrackings] = useState([])
  const [latestTrackings, setLatestTrackings] = useState([])
  const [dayType, setDayType] = useState(1)
  const [project, setProject] = useState(null)
  const [country, setCountry] = useState(null)
  const [showTwoTrackingsWarning, setShowTwoTrackingsWarning] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [countriesOptions, setCountriesOptions] = useState([])
  const [projectsOptions, setProjectsOptions] = useState([])
  const token = getCookie('token')
  const userId = getCookie('userId')
  const { t, i18n } = useTranslation()


  useEffect(() => {
    getCalendarTrackings(true)
  }, [calendarTrackingsStartDate, calendarTrackingsEndDate])


  const getCalendarTrackings = preserveSelected => {
    if (setCalendarTrackingsStartDate && calendarTrackingsEndDate) {
      const url = `api/trackings?limit=9999&offset=0&user=${userId}$start_date=${calendarTrackingsStartDate}&end_date=${calendarTrackingsEndDate}`
      axios.get(url, {
        headers: { Authorization: `bearer ${token}` } 
      })
        .then(response => {
          if (preserveSelected) {
            const newTrackings = calendarTrackings.filter(tracking => tracking.new)
            setCalendarTrackings([...response.data.items, ...newTrackings])
          } else {
            setCalendarTrackings(response.data.items)
          }
        })
    }
  }


  useEffect(() => {
    getLatestTrackings()
  }, [])


  useEffect(() => {
    if (refreshTrackings) {
      getLatestTrackings()
      getCalendarTrackings()
      dispatch({ type: 'setData', field: 'refreshTrackings', data: false })
    }
  }, [refreshTrackings])


  const getLatestTrackings = () => {
    const url = `api/trackings/latest?limit=4`
    axios.get(url, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        setLatestTrackings(response.data)
      })
  }


  useEffect(() => {
    axios.get(`api/countries/available`, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        const countriesList = response.data.map(country => { return {value: country.id, label: t(`settings.countryCodes.${country.name}`)}})
        setCountriesOptions(countriesList)
      })
  }, [])


  useEffect(() => {
    axios.get(`api/projects/available`, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        const projectsList = response.data
          .map(project => { return {value: project.id, label: project.name}})
        setProjectsOptions(projectsList)
      })
  }, [])


  useEffect(() => {
    setIsButtonDisabled(!project || !country || !calendarTrackings.filter(day => day.new).length)
  }, [project, country, calendarTrackings])


  function LatestTrackingItem({ project, country }) {
    return (
      <button className="latest-tracking ripple-effect" onClick={() => {
        setCountry(countriesOptions.find(item => item.value === country.id))
        setProject(projectsOptions.find(item => item.value === project.id))
      }}>
        <div className="text">{project.name}</div>
        <div className="text color-text-light">{t('dashboard.quickLogComponent.in')}</div>
        <div className="text">{t(`settings.countryCodes.${country.name}`)}</div>
      </button>
    )
  }
  
  
  function DayTypeSelect({ selectedType, changeHandler }) {
    return <div className="day-type-select">
      <button className={`day ${selectedType === 1 && 'working-day-selected ripple-effect ripple-effect'}`} onClick={() => changeHandler(1)}>{t('dashboard.quickLogComponent.workingDay')}</button>
      <button className={`day ${selectedType === 2 && 'non-working-day-selected ripple-effect ripple-effect'}`} onClick={() => changeHandler(2)}>{t('dashboard.quickLogComponent.nonWorkingDay')}</button>
    </div>
  }


  const handleCreateTracking = () => {
    const newTrackingData = {
      user: `api/users/${userId}`,
      type: dayType,
      project: `api/projects/${project.value}`,
      country: `api/countries/${country.value}`,
      dates: calendarTrackings.filter(day => day.new).map(day => day.date)
    }

    axios.post(`api/trackings`, newTrackingData, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(() => {
        dispatch({ type: 'setData', field: 'refreshTrackings', data: true })
        setSuccessMessage(t('dashboard.quickLogComponent.trackingSuccessMessage'))
        setCountry(null)
        setProject(null)
      })
  }


  function newlyAddedDays(day) {
    return calendarTrackings.filter(tracking => DateUtils.isSameDay(new Date(tracking.date), day)).length === 1 
      && calendarTrackings.find(tracking => DateUtils.isSameDay(new Date(tracking.date), day) && tracking.new)
  }
  function workingDays(day) {
    return calendarTrackings.find(tracking => DateUtils.isSameDay(new Date(tracking.date), day) && tracking.type === 1 && !tracking.new)
  }
  function nonWorkingDays(day) {
    return calendarTrackings.find(tracking => DateUtils.isSameDay(new Date(tracking.date), day) && tracking.type === 2 && !tracking.new)
  }
  function workingAndNonWorkingDays(day) { 
    return calendarTrackings.find(tracking => DateUtils.isSameDay(new Date(tracking.date), day) && tracking.type === 1 && !tracking.new)
      && calendarTrackings.find(tracking => DateUtils.isSameDay(new Date(tracking.date), day) && tracking.type === 2 && !tracking.new)
  }
  function workingAndNewDays(day) {
    return calendarTrackings.find(tracking => DateUtils.isSameDay(new Date(tracking.date), day) && tracking.type === 1 && !tracking.new)
      && calendarTrackings.find(tracking => DateUtils.isSameDay(new Date(tracking.date), day) && tracking.new)
  }
  function nonWorkingAndNewDays(day) {
    return calendarTrackings.find(tracking => DateUtils.isSameDay(new Date(tracking.date), day) && tracking.type === 2 && !tracking.new)
      && calendarTrackings.find(tracking => DateUtils.isSameDay(new Date(tracking.date), day) && tracking.new)
  }

  const handleDayClick = (day, { selected }) => {
    const newSelectedDays = [...calendarTrackings]
    if (selected) {
      const matchingDays = calendarTrackings.filter(tracking => DateUtils.isSameDay(new Date(tracking.date), day))
      if (matchingDays.find(matchingDay => matchingDay.new)) { // if new day clicked again, remove it
        const trackingIndex = calendarTrackings.findIndex(tracking => DateUtils.isSameDay(new Date(tracking.date), day) && tracking.new)
        newSelectedDays.splice(trackingIndex, 1);
      } 
      else if (matchingDays.length === 1) { // if clicked on a day that is already selected, but it's not new
        newSelectedDays.push({
          date: day,
          type: dayType,
          new: true,
        });
      } else { // if clicked on day that already has two entries
        setShowTwoTrackingsWarning(true)
      }
    } else {
      newSelectedDays.push({
        date: day,
        type: 1,
        new: true,
      });
    }
    setCalendarTrackings(newSelectedDays);
  }

  return (
    <div className="quick-log-component">
      <div className="text bold color-text-light">{t('dashboard.quickLogComponent.title')}</div>
      { latestTrackings.length !== 0 && <div className="label color-text-light">{t('dashboard.quickLogComponent.subtitle')}</div> } 
      <div className="latest-trackings">
        {
          latestTrackings.map((tracking, i) => <LatestTrackingItem key={i} project={tracking.project} country={tracking.country} />)
        }
      </div>

      <div className="pickers-line">
        <div className="calendar">
          <DayPicker
            localeUtils={MomentLocaleUtils}
            locale={i18n.language}
            selectedDays={calendarTrackings.map(day => new Date(day.date))}
            showOutsideDays 
            modifiers={{ newlyAddedDays, workingDays, nonWorkingDays, workingAndNonWorkingDays, workingAndNewDays, nonWorkingAndNewDays }}
            onDayClick={handleDayClick}
            renderDay={day => <div className="calendar-cell">{day.getDate()}</div>}
            onMonthChange={month => {
              setCalendarTrackingsStartDate(moment(month).startOf('month').add(-1, 'months').format('YYYY-MM-DD'))
              setCalendarTrackingsEndDate(moment(month).endOf('month').add(1, 'months').format('YYYY-MM-DD'))
            }}
          />
        </div>

        <div className="inputs">
          <div className="input-wrapper">
            <div className="label">{t('dashboard.quickLogComponent.workingType')}</div>
            <DayTypeSelect 
              selectedType={dayType}
              changeHandler={option => setDayType(option)}
            />
          </div>

          <div className="input-wrapper">
            <div className="label">{t('dashboard.quickLogComponent.countryLabel')}</div>
            <QuickLogSelect 
              options={countriesOptions}
              selectedOption={country}
              changeHandler={option => setCountry(option)}
              width='100%'
            />
          </div>

          <div className="input-wrapper">
            <div className="label">{t('dashboard.quickLogComponent.projectLabel')}</div>
            <QuickLogSelect
              options={projectsOptions}
              selectedOption={project}
              changeHandler={option => setProject(option)}
              width='100%'
            />
          </div>
          
          { cancelHandler && <button className="button button-secondary ripple-effect" onClick={cancelHandler}>{t('dashboard.quickLogComponent.cancelButton')}</button> }
          
          <button className={`button button-primary ripple-effect ripple-effect-lighter" ${isButtonDisabled && 'button-primary-disabled'}`} onClick={handleCreateTracking}
          >{t('dashboard.quickLogComponent.createTrackingButton')}</button>
        </div>
      </div>
      
      {
        showTwoTrackingsWarning && <Snackbar type="warning" text={t('dashboard.quickLogComponent.trackingConflictMessage')} closeHandler={() => setShowTwoTrackingsWarning(false)} />
      }
      {
        successMessage && <Snackbar type="success" text={successMessage} closeHandler={() => setSuccessMessage(null)} />
      }
    </div>
  );
}

export default QuickLogComponent;
