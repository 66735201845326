import React, { useState, useEffect } from 'react';
import './DashboardPage.scss';
import WorkingDaysComponent from './grid-elements/WorkingDaysComponent/WorkingDaysComponent';
import NonWorkingDaysComponent from './grid-elements/NonWorkingDaysComponent/NonWorkingDaysComponent';
import DaysWithTrackingsComponent from './grid-elements/DaysWithTrackingsComponent/DaysWithTrackingsComponent';
import CountriesComponent from './grid-elements/CountriesComponent/CountriesComponent';
import ProjectsComponent from './grid-elements/ProjectsComponent/ProjectsComponent';
import TrackingByCountriesComponent from './grid-elements/TrackingByCountriesComponent/TrackingByCountriesComponent';
import QuickLogComponent from './grid-elements/QuickLogComponent/QuickLogComponent';
import AllTrackingsComponent from './grid-elements/AllTrackingsComponent/AllTrackingsComponent';
import FilterSelect from '../../common/FilterSelect/FilterSelect';
import DateFilter from '../../common/DateFilter/DateFilter';
import plusIcon from '../../../assets/plus-icon.svg';
import QuickLogModal from './QuickLogModal/QuickLogModal';
import { withRouter } from 'react-router-dom'
import { useStateValue } from '../../../state/state'
import { getPermission } from '../../../permissions'
import { getCookie } from '../../../utils'
import { default as axios } from '../../../axiosSettings'
import { useTranslation } from 'react-i18next'


let previousScrollValue = 0


function DashboardPage(props) {
  const { t } = useTranslation()

  const statusesFilterOptions = [
    { value: 'all', label: t('filters.statusAll') },
    { value: 1, label: t('filters.statusOpen') },
    { value: 2, label: t('filters.statusConfirmed') },
    { value: 3, label: t('filters.statusClosed') },
  ]

  const [{ userRole, userData, refreshTrackings }, dispatch] = useStateValue()
  const [usersFilterOptions, setUsersFilterOptions] = useState([])
  const [usersFilterOption, setUsersFilterOption] = useState(null)
  const [projectsFilterOptions, setProjectsFilterOptions] = useState([])
  const [projectsFilterOption, setProjectsFilterOption] = useState(null)
  const [countriesFilterOptions, setCountriesFilterOptions] = useState([])
  const [countriesFilterOption, setCountriesFilterOption] = useState(null)
  const [statusesFilterOption, setStatusesFilterOption] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [taxPeriodSelected, setTaxPeriodSelected] = useState(true)
  const [trackingsSummary, setTrackingsSummary] = useState({})
  const canSeeUsersFilter = getPermission(userRole, 'canSeeUsersFilter')
  const canSeeProjectsFilter = getPermission(userRole, 'canSeeProjectsFilter')
  const canSeeCountriesFilter = getPermission(userRole, 'canSeeCountriesFilter')
  const canSeeStatusFilter = getPermission(userRole, 'canSeeStatusFilter')
  const canSeeDateFilter = getPermission(userRole, 'canSeeDateFilter')
  const canSeeDaysWithTrackings = getPermission(userRole, 'canSeeDaysWithTrackings')
  const token = getCookie('token')
  const userId = getCookie('userId')


  useEffect(() => {
    setStatusesFilterOption(statusesFilterOptions[0])
  }, [t])


  useEffect(() => {
    if (!userRole || !userData.id) return

    if (userRole === 'ROLE_USER') {
      setUsersFilterOption({ value: userData.id, label: userData.name })
      return
    } 

    axios.get(`api/users/all`, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        const userList = response.data.map(user => { return {value: user.id, label: user.name}})
        userList.unshift({ value: 'all', label: t('filters.allUsers') })
        setUsersFilterOptions(userList)
        const currentUser = response.data.find(user => user.id.toString() === userId)
        setUsersFilterOption({ value: currentUser.id, label: currentUser.name })
      })
  }, [userRole, userData])


  useEffect(() => {
    getProjectsOptions()
    getCountriesOptions()
  }, [])


  useEffect(() => {
    getTrackingsSummary()
    getProjectsOptions()
    getCountriesOptions()
    dispatch({ type: 'setData', field: 'refreshTrackings', data: false })
  }, [refreshTrackings])


  useEffect(() => {
    getTrackingsSummary()
  }, [
    usersFilterOption,
    projectsFilterOption,
    countriesFilterOption,
    statusesFilterOption,
    startDate,
    endDate,
    taxPeriodSelected,
  ])


  const getProjectsOptions = () => {
    axios.get(`api/projects/all`, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        const projectsList = response.data.map(project => { return {value: project.id, label: project.name}})
        projectsList.unshift({ value: 'all', label: t('filters.allProjects') })
        setProjectsFilterOptions(projectsList)
        setProjectsFilterOption(projectsList && projectsList[0])
      })
  }


  const getCountriesOptions = () => {
    axios.get(`api/countries/all`, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        const countriesList = response.data.map(country => { return {value: country.id, label: t(`settings.countryCodes.${country.name}`)}})
        countriesList.unshift({ value: 'all', label: t('filters.allCountries') })
        setCountriesFilterOptions(countriesList)
        setCountriesFilterOption(countriesList && countriesList[0])
      })
  }


  const getTrackingsSummary = () => {
    if (!usersFilterOption) return

    let url = `api/trackings/summary?`
    if (usersFilterOption && usersFilterOption.value !== 'all') url += `&user=${usersFilterOption.value}`
    if (projectsFilterOption && projectsFilterOption.value !== 'all') url += `&project=${projectsFilterOption.value}`
    if (countriesFilterOption && countriesFilterOption.value !== 'all') url += `&country=${countriesFilterOption.value}`
    if (statusesFilterOption && statusesFilterOption.value !== 'all') url += `&status=${statusesFilterOption.value}`
    if (startDate) url += `&start_date=${startDate.split('T')[0]}`
    if (endDate) url += `&end_date=${endDate.split('T')[0]}`
    if (taxPeriodSelected) url += `&current_tax_period=1`

    axios.get(url, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        setTrackingsSummary(response.data)
      })
  }


  const handleDesktopScroll = () => {
    const windowWidth = window.innerWidth
    if (windowWidth <= 768) return

    const headerTextHeight = document.querySelector('h1').getBoundingClientRect().height
    if (
      (windowWidth > 1400 && window.scrollY > 16)
      || (windowWidth > 768 && window.scrollY > headerTextHeight + 20 + 16) // 20 is header margin-bottom
      ) {
        document.querySelector('.desktop-filters-sticky').classList = 'desktop-filters-sticky'
    } else {
      document.querySelector('.desktop-filters-sticky').classList = 'desktop-filters-sticky invisible'
    }
  }


  const handleMobileScroll = () => {
    if (props.location.pathname.toString().includes('new-tracking')) return
    const windowWidth = window.innerWidth
    if (windowWidth > 768) return
    // if (!document.querySelector('.mobile-filters-sticky')) return

    const scrollValue = window.pageYOffset
    if (previousScrollValue - scrollValue < 0 || window.scrollY < 80) {
      document.querySelector('.mobile-filters-sticky').classList = 'mobile-filters-sticky invisible'
    } else if (previousScrollValue - scrollValue > 0) {
      document.querySelector('.mobile-filters-sticky').classList = 'mobile-filters-sticky'
    }
    previousScrollValue = scrollValue
  }


  useEffect(() => {
    document.addEventListener("scroll", handleDesktopScroll)
    document.addEventListener("scroll", handleMobileScroll)
    return () => {
      document.removeEventListener("scroll", handleDesktopScroll)
      document.removeEventListener("scroll", handleMobileScroll)
    }
  })
  
  const setDateFilters = (startDate, endDate, taxPeriodSelected) => {
    setStartDate(startDate)
    setEndDate(endDate)
    setTaxPeriodSelected(taxPeriodSelected)
  }


  const renderGreetingText = () => {
    const hours = new Date().getHours()
    if (hours < 6) return t('dashboard.greetings.night')
    else if (hours < 12) return t('dashboard.greetings.morning')
    else if (hours < 17) return t('dashboard.greetings.afternoon')
    else if (hours < 20) return t('dashboard.greetings.evening')
    else return t('dashboard.greetings.night')
  }


  const renderFilters = () => <>
    { canSeeUsersFilter && <FilterSelect 
      placeholder={t('filters.userPlaceholder')}
      options={usersFilterOptions}
      selectedOption={usersFilterOption}
      changeHandler={option => setUsersFilterOption(option)}
    /> }
    { canSeeProjectsFilter && <FilterSelect 
      placeholder={t('filters.projectPlaceholder')}
      options={projectsFilterOptions}
      selectedOption={projectsFilterOption}
      changeHandler={option => setProjectsFilterOption(option)}
    /> }
    { canSeeCountriesFilter && <FilterSelect 
      placeholder={t('filters.countryPlaceholder')}
      options={countriesFilterOptions}
      selectedOption={countriesFilterOption}
      changeHandler={option => setCountriesFilterOption(option)}
    /> }
    { canSeeStatusFilter && <FilterSelect 
      placeholder={t('filters.statusPlaceholder')}
      options={statusesFilterOptions}
      selectedOption={statusesFilterOption}
      changeHandler={option => setStatusesFilterOption(option)}
    /> }
    { canSeeDateFilter && <DateFilter changeHandler={setDateFilters}/> }
  </>

  
  return (
    <div className="dashboard-page">

      <header className="header-desktop">
        <h1>{renderGreetingText()}, <span className="color-primary-default">{userData.name}</span></h1>
        <div className="filters-wrapper">
          <div className="label">{t('filters.filterBy')}</div>
          { renderFilters() }
        </div>
      </header>

      { !props.location.pathname.toString().includes('new-tracking') &&
        <header className="header-mobile">
          <h3>{t('dashboard.greetings.mobileGreetings')} <span className="color-primary-default">{userData.name}</span></h3>

          <div className="filters-wrapper-mobile">
            <div className="label">{t('filters.filterBy')}</div>
            <div className="filters-list">
            { renderFilters() }
            </div>
          </div>

          <div className="mobile-filters-sticky invisible">
            <div className="filters-list">
            { renderFilters() }
            </div>
          </div>
        </header>
      }

      <div className="desktop-filters-sticky invisible">
        <div className="filters-list">
          <div className="label">{t('filters.filterBy')}</div>
          { renderFilters() }
        </div>
      </div>

      { !props.location.pathname.toString().includes('new-tracking') && 
        <>
          <section className={`stats-grid-container ${!canSeeDaysWithTrackings && 'stats-grid-container-user'}`}>
            <div className="working-days"><WorkingDaysComponent 
                trackingsSummary={trackingsSummary}
              /></div>
              <div className="not-working-days"><NonWorkingDaysComponent 
                trackingsSummary={trackingsSummary}
              /></div>
              { canSeeDaysWithTrackings && <div className="days-with-trackings"><DaysWithTrackingsComponent
                trackingsSummary={trackingsSummary}
              /></div> }
              <div className="countries"><CountriesComponent 
                usersFilterOption={usersFilterOption}
                projectsFilterOption={projectsFilterOption}
                countriesFilterOption={countriesFilterOption}
                statusesFilterOption={statusesFilterOption}
                startDate={startDate}
                endDate={endDate}
                taxPeriodSelected={taxPeriodSelected}
              /></div>
              <div className="projects"><ProjectsComponent
                usersFilterOption={usersFilterOption}
                projectsFilterOption={projectsFilterOption}
                countriesFilterOption={countriesFilterOption}
                statusesFilterOption={statusesFilterOption}
                startDate={startDate}
                endDate={endDate}
                taxPeriodSelected={taxPeriodSelected}
              /></div>
          </section>
          <section className="grid-container">
            <div className="countries-tracking"><TrackingByCountriesComponent 
              usersFilterOption={usersFilterOption}
              projectsFilterOption={projectsFilterOption}
              countriesFilterOption={countriesFilterOption}
              statusesFilterOption={statusesFilterOption}
              startDate={startDate}
              endDate={endDate}
              taxPeriodSelected={taxPeriodSelected}
            /></div>
            <div className="quick-log hide-small-screens"><QuickLogComponent /></div>
            <div className="all-trackings hide-small-screens">
              <AllTrackingsComponent 
                usersFilterOption={usersFilterOption}
                usersFilterOptions={usersFilterOptions}
                projectsFilterOption={projectsFilterOption}
                projectsFilterOptions={projectsFilterOptions}
                countriesFilterOption={countriesFilterOption}
                countriesFilterOptions={countriesFilterOptions}
                statusesFilterOption={statusesFilterOption}
                startDate={startDate}
                endDate={endDate}
                taxPeriodSelected={taxPeriodSelected}
              />
            </div>
          </section>
        </>
      }

      <div className="add-tracking-button show-small-screens" onClick={() => {
        props.history.push('/dashboard/new-tracking')
        window.scrollTo(0 ,0)
      }}>
        <button className="button-content ripple-effect ripple-effect-lighter ripple-effect-circle">
          <img src={plusIcon} alt="add tracking" />
        </button>
      </div>
      
      {
        props.location.pathname.toString().includes('new-tracking') && <QuickLogModal cancelHandler={() => {
          props.history.push('/dashboard')
        }} />
      }
    </div>
  );
}

export default withRouter(DashboardPage)
