import React, { useState, useEffect } from 'react';
import './ReportPage.scss';
import FilterSelect from '../../common/FilterSelect/FilterSelect';
import SortableHeader from '../../common/SortableHeader/SortableHeader';
import PopoverMenu from '../../common/PopoverMenu/PopoverMenu';
import DateFilter from '../../common/DateFilter/DateFilter';
import downloadIcon from '../../../assets/download-icon.svg';
import mailIcon from '../../../assets/mail-icon.svg';
import csvIcon from '../../../assets/csv-icon.svg';
import pdfIcon from '../../../assets/pdf-icon.svg';
import closeIcon from '../../../assets/close-icon-white.svg';
import editIcon from '../../../assets/edit-icon.svg';
import saveIcon from '../../../assets/save-icon.svg';
import editCloseIcon from '../../../assets/close-icon-black-lighter.svg';
import SelectInput from '../../common/SelectInput/SelectInput';
import CalendarInput from '../../common/CalendarInput/CalendarInput';
import { useStateValue } from '../../../state/state'
import { getPermission } from '../../../permissions'
import { getCookie } from '../../../utils'
import { default as axios } from '../../../axiosSettings'
import { ITEMS_PER_PAGE } from '../../../config';
import moment from 'moment'
import Snackbar from '../../common/Snackbar/Snackbar';
import fileDownload from 'js-file-download'
import { useTranslation } from 'react-i18next'


let previousScrollValue = 0


function ReportPage() {
  const { t } = useTranslation()

  const statusesFilterOptions = [
    { value: 'all', label: t('filters.statusAll') },
    { value: 1, label: t('filters.statusOpen') },
    { value: 2, label: t('filters.statusConfirmed') },
    { value: 3, label: t('filters.statusClosed') },
  ]
  const typesOptions = [
    { value: 1, label: t('dashboard.allTrackingsComponent.workingLabel') },
    { value: 2, label: t('dashboard.allTrackingsComponent.nonWorkingLabel') },
  ]
  const statusesOptions = [
    { value: 1, label: t('dashboard.allTrackingsComponent.openStatusLabel') },
    { value: 2, label: t('dashboard.allTrackingsComponent.confirmedStatusLabel') },
    { value: 3, label: t('dashboard.allTrackingsComponent.closedStatusLabel') },
  ]
  const limitedStatusesOptions = [
    { value: 1, label: t('dashboard.allTrackingsComponent.openStatusLabel') },
    { value: 2, label: t('dashboard.allTrackingsComponent.confirmedStatusLabel') },
  ]

  const [{ userRole, userData }] = useStateValue()
  const [trackings, setTrackings] = useState([])
  const [trackingsTotalCount, setTrackingsTotalCount] = useState(null)
  const [limit, setLimit] = useState(ITEMS_PER_PAGE)
  const [offset, setOffset] = useState(0)
  const [usersFilterOptions, setUsersFilterOptions] = useState([])
  const [usersFilterOption, setUsersFilterOption] = useState(null)
  const [projectsFilterOptions, setProjectsFilterOptions] = useState([])
  const [projectsFilterOption, setProjectsFilterOption] = useState(null)
  const [countriesFilterOptions, setCountriesFilterOptions] = useState([])
  const [countriesFilterOption, setCountriesFilterOption] = useState(null)
  const [statusesFilterOption, setStatusesFilterOption] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [sortBy, setSortBy] = useState('date')
  const [sortDirection, setSortDirection] = useState('desc')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [taxPeriodSelected, setTaxPeriodSelected] = useState(true)
  const [showMobileDownloadOptions, setShowMobileDownloadOptions] = useState(null)
  const [editedRow, setEditedRow] = useState(null)
  const [successMessaage, setSuccessMessage] = useState(null)
  const canExportCSVFile = getPermission(userRole, 'canExportCSVFile')
  const canExportPDFFile = getPermission(userRole, 'canExportPDFFile')
  const canSeeUsersFilter = getPermission(userRole, 'canSeeUsersFilter')
  const canSeeProjectsFilter = getPermission(userRole, 'canSeeProjectsFilter')
  const canSeeCountriesFilter = getPermission(userRole, 'canSeeCountriesFilter')
  const canSeeStatusFilter = getPermission(userRole, 'canSeeStatusFilter')
  const canSeeDateFilter = getPermission(userRole, 'canSeeDateFilter')
  const token = getCookie('token')
  const userId = getCookie('userId')


  useEffect(() => {
    setStatusesFilterOption(statusesFilterOptions[0])
  }, [t])


  useEffect(() => {
    if (!userRole || !userData.id) return

    if (userRole === 'ROLE_USER') {
      setUsersFilterOption({ value: userData.id, label: userData.name })
      return
    } 

    axios.get(`api/users/all`, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        const userList = response.data.map(user => { return {value: user.id, label: user.name}})
        userList.unshift({ value: 'all', label: t('filters.allUsers') })
        setUsersFilterOptions(userList)
        const currentUser = response.data.find(user => user.id.toString() === userId)
        setUsersFilterOption({ value: currentUser.id, label: currentUser.name })
      })
  }, [userRole, userData])


  useEffect(() => {
    axios.get(`api/projects/all`, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        const projectsList = response.data.map(project => { return {value: project.id, label: project.name}})
        projectsList.unshift({ value: 'all', label: t('filters.allProjects') })
        setProjectsFilterOptions(projectsList)
        setProjectsFilterOption(projectsList && projectsList[0])
      })
  }, [])


  useEffect(() => {
    axios.get(`api/countries/all`, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        const countriesList = response.data.map(country => { return {value: country.id, label: t(`settings.countryCodes.${country.name}`)}})
        countriesList.unshift({ value: 'all', label: t('filters.allCountries') })
        setCountriesFilterOptions(countriesList)
        setCountriesFilterOption(countriesList && countriesList[0])
      })
  }, [])


  useEffect(() => {
    getTrackings()
  }, [sortBy, sortDirection, searchQuery, usersFilterOption, projectsFilterOption, countriesFilterOption, statusesFilterOption, startDate, endDate, taxPeriodSelected])


  const getTrackings = () => {
    if (!usersFilterOption) return

    let url = `api/trackings?limit=${limit}&offset=0&sort_by=${sortBy}&sort_direction=${sortDirection}&search=${searchQuery}`
    if (usersFilterOption && usersFilterOption.value !== 'all') url += `&user=${usersFilterOption.value}`
    if (projectsFilterOption && projectsFilterOption.value !== 'all') url += `&project=${projectsFilterOption.value}`
    if (countriesFilterOption && countriesFilterOption.value !== 'all') url += `&country=${countriesFilterOption.value}`
    if (statusesFilterOption && statusesFilterOption.value !== 'all') url += `&status=${statusesFilterOption.value}`
    if (startDate) url += `&start_date=${startDate.split('T')[0]}`
    if (endDate) url += `&end_date=${endDate.split('T')[0]}`
    if (taxPeriodSelected) url += `&current_tax_period=1`

    axios.get(url, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        setTrackings(response.data.items)
        setTrackingsTotalCount(response.data.totalCount)
      })
  }


  const getMoreTrackings = () => {
    const newOffset = offset + ITEMS_PER_PAGE
    let url = `api/trackings?sort_by=${sortBy}&sort_direction=${sortDirection}&limit=${ITEMS_PER_PAGE}&offset=${newOffset}&search=${searchQuery}`
    if (usersFilterOption && usersFilterOption.value !== 'all') url += `&user=${usersFilterOption.value}`
    if (projectsFilterOption && projectsFilterOption.value !== 'all') url += `&project=${projectsFilterOption.value}`
    if (countriesFilterOption && countriesFilterOption.value !== 'all') url += `&country=${countriesFilterOption.value}`
    if (statusesFilterOption && statusesFilterOption.value !== 'all') url += `&status=${statusesFilterOption.value}`
    if (startDate) url += `&start_date=${startDate.split('T')[0]}`
    if (endDate) url += `&end_date=${endDate.split('T')[0]}`
    if (taxPeriodSelected) url += `&current_tax_period=1`
    
    axios.get(url, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        setOffset(newOffset)
        setTrackings(trackings => [...trackings, ...response.data.items])
        setLimit(limit + ITEMS_PER_PAGE) 
      })
  }


  const handleSaveChanges = () => {
    const changes = {
      date: editedRow.date,
      user: `api/users/${editedRow.user.id}`,
      type: editedRow.type,
      country: `api/countries/${editedRow.country.id}`,
      project: `api/projects/${editedRow.project.id}`,
      status: editedRow.status
    }

    axios.patch(`api/trackings/${editedRow.id}`, changes, {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/merge-patch+json',
      }
    })
      .then(() => {
        setEditedRow(null)
        getTrackings()
        setSuccessMessage(t('dashboard.allTrackingsComponent.editConfirmMessage'))
      })
  }


  const handleConfirmTracking = trackingId => {
    axios.patch(`api/trackings/${trackingId}`, {
      status: 2,
    }, {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/merge-patch+json',
      }
    })
      .then(() => {
        getTrackings()
        setSuccessMessage(t('dashboard.allTrackingsComponent.confirmMessage'))
      })
  }


  const handleDeleteTracking = trackingId => {
    axios.patch(`api/trackings/${trackingId}`, {
      deleted: true,
    }, {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/merge-patch+json',
      }
    })
      .then(() => {
        getTrackings()
        setSuccessMessage(t('dashboard.allTrackingsComponent.deleteConfirmMessage'))
      })
  }


  const handleDesktopScroll = () => {
    const windowWidth = window.innerWidth
    if (windowWidth <= 768) return

    const headerTextsHeight = document.querySelector('h1').getBoundingClientRect().height + document.querySelector('.header-text').getBoundingClientRect().height
    if (
      (windowWidth > 1400 && window.scrollY > 16)
      || (windowWidth > 768 && window.scrollY > headerTextsHeight + 65)
      ) {
      document.querySelector('.desktop-filters-sticky').classList = 'desktop-filters-sticky'
    } else {
      document.querySelector('.desktop-filters-sticky').classList = 'desktop-filters-sticky invisible'
    }
  }

  const handleMobileScroll = () => {
    const windowWidth = window.innerWidth
    if (windowWidth > 768) return

    const scrollValue = window.pageYOffset
    if (previousScrollValue - scrollValue < 0 || window.scrollY < 80) {
      document.querySelector('.mobile-filters-sticky').classList = 'mobile-filters-sticky invisible'
    } else if (previousScrollValue - scrollValue > 0) {
      document.querySelector('.mobile-filters-sticky').classList = 'mobile-filters-sticky'
    }
    previousScrollValue = scrollValue
  }
    
  useEffect(() => {
    document.addEventListener("scroll", handleDesktopScroll)
    document.addEventListener("scroll", handleMobileScroll)
    return () => {
      document.removeEventListener("scroll", handleDesktopScroll)
      document.removeEventListener("scroll", handleMobileScroll)
    }
  })

  const setDateFilters = (startDate, endDate, taxPeriodSelected) => {
    setStartDate(startDate)
    setEndDate(endDate)
    setTaxPeriodSelected(taxPeriodSelected)
  }


  const renderTypeLabel = type => {
    if (type === 1) return <span className="label-working">{t('dashboard.allTrackingsComponent.workingLabel')}</span>
    if (type === 2) return <span className="label-non-working">{t('dashboard.allTrackingsComponent.nonWorkingLabel')}</span>
    return <>{type}</>
  }

  const renderStatus = status => {
    if (status === 1) return t('dashboard.allTrackingsComponent.openStatusLabel')
    if (status === 2) return t('dashboard.allTrackingsComponent.confirmedStatusLabel')
    if (status === 3) return t('dashboard.allTrackingsComponent.closedStatusLabel')
    return status
  }


  const renderFilters = () => <>
    { canSeeUsersFilter && <FilterSelect 
      placeholder={t('filters.userPlaceholder')}
      options={usersFilterOptions}
      selectedOption={usersFilterOption}
      changeHandler={option => setUsersFilterOption(option)}
    /> }
    { canSeeProjectsFilter && <FilterSelect 
      placeholder={t('filters.projectPlaceholder')}
      options={projectsFilterOptions}
      selectedOption={projectsFilterOption}
      changeHandler={option => setProjectsFilterOption(option)}
    /> }
    { canSeeCountriesFilter && <FilterSelect 
      placeholder={t('filters.countryPlaceholder')}
      options={countriesFilterOptions}
      selectedOption={countriesFilterOption}
      changeHandler={option => setCountriesFilterOption(option)}
    /> }
    { canSeeStatusFilter && <FilterSelect 
      placeholder={t('filters.statusPlaceholder')}
      options={statusesFilterOptions}
      selectedOption={statusesFilterOption}
      changeHandler={option => setStatusesFilterOption(option)}
    /> }
    { canSeeDateFilter && <DateFilter changeHandler={setDateFilters}/> }
  </>


  const handleCSVexport = () => {
    let url = `api/trackings/report-csv?sort_by=${sortBy}&sort_direction=${sortDirection}&search=${searchQuery}`
    if (usersFilterOption && usersFilterOption.value !== 'all') url += `&user=${usersFilterOption.value}`
    if (projectsFilterOption && projectsFilterOption.value !== 'all') url += `&project=${projectsFilterOption.value}`
    if (countriesFilterOption && countriesFilterOption.value !== 'all') url += `&country=${countriesFilterOption.value}`
    if (statusesFilterOption && statusesFilterOption.value !== 'all') url += `&status=${statusesFilterOption.value}`
    if (startDate) url += `&start_date=${startDate.split('T')[0]}`
    if (endDate) url += `&end_date=${endDate.split('T')[0]}`
    if (taxPeriodSelected) url += `&current_tax_period=1`
   
    axios.get(url, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        const bomString = '\ufeff';
        fileDownload(response.data, `report-${moment().format('YYYY-MM-DD-HH-mm')}.csv`, null, bomString)
      })
  }


  const handlePDFexport = () => {
    let url = `api/trackings/report-pdf?sort_by=${sortBy}&sort_direction=${sortDirection}&search=${searchQuery}`
    if (usersFilterOption && usersFilterOption.value !== 'all') url += `&user=${usersFilterOption.value}`
    if (projectsFilterOption && projectsFilterOption.value !== 'all') url += `&project=${projectsFilterOption.value}`
    if (countriesFilterOption && countriesFilterOption.value !== 'all') url += `&country=${countriesFilterOption.value}`
    if (statusesFilterOption && statusesFilterOption.value !== 'all') url += `&status=${statusesFilterOption.value}`
    if (startDate) url += `&start_date=${startDate.split('T')[0]}`
    if (endDate) url += `&end_date=${endDate.split('T')[0]}`
    if (taxPeriodSelected) url += `&current_tax_period=1`

    axios.get(url, {
      headers: { 
        Authorization: `bearer ${token}`
      },
      responseType: 'blob',
    })
      .then(response => {
        fileDownload(response.data, `report-${moment().format('YYYY-MM-DD-HH-mm')}.pdf`)
      })
  }


  const TableRow = ({ tracking, editedRow, setEditedRow }) => {
    const [{ userRole }] = useStateValue()
    const canEditTracking = getPermission(userRole, 'canEditTracking')
    const canDeleteTracking = getPermission(userRole, 'canDeleteTracking')
    const canConfirmTracking = getPermission(userRole, 'canConfirmTracking')
    const canCloseTracking = getPermission(userRole, 'canCloseTracking')

    return (
      <div className={`table-row ${editedRow && editedRow.id  === tracking.id && 'small-vertical-padding'}`}> 
        {
          editedRow && editedRow.id === tracking.id 
            ? <div className="table-cell text">
                <CalendarInput
                  value={editedRow.date}
                  placeholder={t('dashboard.allTrackingsComponent.calendarPlaceholder')}
                  changeHandler={day => {
                    const newEditedRow = { ...editedRow, date: day }
                    setEditedRow(newEditedRow)
                  }}
                />
              </div>
            : <div className="table-cell text">{moment(tracking.date).format('YYYY-MM-DD')}</div>
        }
        {
          usersFilterOptions.length && editedRow && editedRow && editedRow.id === tracking.id 
            ? <div className="table-cell text">
                <SelectInput 
                  options={usersFilterOptions.slice(1)}
                  selectedOption={usersFilterOptions.find(option => option.value === editedRow.user.id)}
                  changeHandler={selectedOption => {
                    const newEditedRow = { ...editedRow, user: { id: selectedOption.value, name: selectedOption.label } }
                    setEditedRow(newEditedRow)
                  }}
                  width='100%'
                  height='30px'
                />
              </div>
            : <div className="table-cell text centered">{tracking.user && tracking.user.name}</div>
        }
        {
          editedRow && editedRow.id === tracking.id 
            ? <div className="table-cell text">
                <SelectInput 
                  options={typesOptions}
                  selectedOption={typesOptions.find(option => option.value === editedRow.type)}
                  changeHandler={option => {
                    const newEditedRow = { ...editedRow, type: option.value }
                    setEditedRow(newEditedRow)
                  }}
                  width='100%'
                  height='30px'
                />
              </div>
            : <div className="table-cell text">{renderTypeLabel(tracking.type)}</div>
        }
        {
          editedRow && editedRow.id === tracking.id 
            ? <div className="table-cell text">
                <SelectInput 
                  options={countriesFilterOptions.slice(1)}
                  selectedOption={countriesFilterOptions.find(option => option.value === editedRow.country.id)}
                  changeHandler={selectedOption => {
                    const newEditedRow = { ...editedRow, country: { id: selectedOption.value, name: selectedOption.label } }
                    setEditedRow(newEditedRow)
                  }}
                  width='100%'
                  height='30px'
                />
              </div>
            : <div className="table-cell text">{tracking.country && t(`settings.countryCodes.${tracking.country.name}`)}</div>
        }
        {
          editedRow && editedRow.id === tracking.id 
            ? <div className="table-cell text">
                <SelectInput 
                  options={projectsFilterOptions.slice(1)}
                  selectedOption={projectsFilterOptions.find(option => option.value === editedRow.project.id)}
                  changeHandler={selectedOption => {
                    const newEditedRow = { ...editedRow, project: { id: selectedOption.value, name: selectedOption.label } }
                    setEditedRow(newEditedRow)
                  }}
                  width='100%'
                  height='30px'
                />
              </div>
            : <div className="table-cell text">{tracking.project && tracking.project.name}</div>
        }
        {
          (userRole && userRole !== 'ROLE_USER') && editedRow && editedRow.id === tracking.id 
            ? <div className="table-cell text">
                <SelectInput 
                  options={canCloseTracking ? statusesOptions : limitedStatusesOptions}
                  selectedOption={statusesOptions.find(option => option.value === editedRow.status)}
                  changeHandler={option => {
                    const newEditedRow = { ...editedRow, status: option.value }
                    setEditedRow(newEditedRow)
                  }}
                  width='100%'
                  height='30px'
                />
              </div>
            : <div className="table-cell text centered">{renderStatus(tracking.status)}</div>
        }
        <div className="table-cell last-cell text">
        { canEditTracking && (!editedRow || !editedRow.id) && <img className="edit-row-icon" src={editIcon} alt="edit row" onClick={() => setEditedRow(tracking)} /> }
          {
            editedRow && editedRow.id === tracking.id && <>
              <img className="save-row-icon" src={saveIcon} alt="save" onClick={handleSaveChanges} />
              <img className="cancel-row-icon" src={editCloseIcon} alt="cancel" onClick={() => setEditedRow(null)} />
            </>
          }
          <PopoverMenu>
            { canDeleteTracking && <div className="popover-menu-option" onClick={() => handleDeleteTracking(tracking.id)}>{t('dashboard.allTrackingsComponent.deleteOption')}</div> }
            { canConfirmTracking && tracking.status !== 2 && <div className="popover-menu-option" onClick={() => handleConfirmTracking(tracking.id)}>{t('dashboard.allTrackingsComponent.confirmOption')}</div> }
          </PopoverMenu>
        </div>
      </div>
    )
  }


  return (
    <div className="report-page">
      <header className="header-desktop">
        <div>
          <h1>{t('report.title')}</h1>
          <div className="text header-text">{t('report.subtitle')}</div>
        </div>
        <div>
          <div className="filters-wrapper">
          <div className="label">{t('filters.filterBy')}</div>
            { renderFilters() }
          </div>
          <div className="exports-line">
            { canExportCSVFile && <button className="button button-secondary ripple-effect" onClick={handleCSVexport}>{t('report.exportCSVButton')}</button> }
            { canExportPDFFile &&<button className="button button-primary ripple-effect ripple-effect-lighter" onClick={handlePDFexport}>{t('report.exportPDFButton')}</button> }
          </div>
        </div>
      </header>

      <div className="desktop-filters-sticky invisible">
        <div className="filters-list">
          <div className="label">{t('filters.filterBy')}</div>
            { renderFilters() }
        </div>
      </div>


      <header className="header-mobile">
        <div className="title-line">
          <h3>{t('report.title')}</h3>
          <input type="text" className="search-bar" placeholder={t('dashboard.allTrackingsComponent.searchInputPlaceholder')} value={searchQuery} onChange={e => setSearchQuery(e.target.value) } />
        </div>

        <div className="filters-wrapper-mobile">
          <div className="label">{t('filters.filterBy')}</div>
          <div className="filters-list">
            { renderFilters() }
          </div>
        </div>

        <div className="mobile-filters-sticky invisible">
          <div className="filters-list">
            { renderFilters() }
          </div>
        </div>
      </header>

      <section className="content-wrapper">
        <header>
          <div className="text bold color-text-light">{t('dashboard.allTrackingsComponent.title')}</div>
          <input type="text" className="search-bar" placeholder={t('dashboard.allTrackingsComponent.searchInputPlaceholder')} value={searchQuery} onChange={e => setSearchQuery(e.target.value) } />
        </header>

        <div className="table-headers">
          <SortableHeader text={t('dashboard.allTrackingsComponent.dateHeader')} sortByText="date" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
          <SortableHeader text={t('dashboard.allTrackingsComponent.nameHeader')} sortByText="user" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
          <SortableHeader text={t('dashboard.allTrackingsComponent.typeHeader')} sortByText="type" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
          <SortableHeader text={t('dashboard.allTrackingsComponent.countryHeader')} sortByText="country" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
          <SortableHeader text={t('dashboard.allTrackingsComponent.projectHeader')} sortByText="project" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
          <SortableHeader text={t('dashboard.allTrackingsComponent.statusHeader')} sortByText="status" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
          <div />
        </div>
        { 
          trackings.map((tracking, i) => <TableRow key={i} tracking={tracking} editedRow={editedRow} setEditedRow={setEditedRow} />) 
        }

        <section className="table-data">
          <div className="label color-text-light">{t('dashboard.allTrackingsComponent.showing')} {trackings.length ? 1 : 0}-{Math.min(limit, trackings.length)} {t('dashboard.allTrackingsComponent.of')} {trackingsTotalCount} {t('dashboard.allTrackingsComponent.entries')}</div>
          {
            trackingsTotalCount > limit && <button className="button button-secondary ripple-effect" onClick={() => {
              getMoreTrackings()
            }}>{t('dashboard.allTrackingsComponent.showMoreButton')}</button>
          }
          <div />
        </section>
      </section>

      <section className="data-list-mobile show-small-screens">
        {
          trackings.map((tracking, i) => <div className="list-item" key={i}> 
            <div className="item-cell">
              <div className="text color-text-light">{t('dashboard.allTrackingsComponent.dateHeader')}</div>
              <div className="grid-cell text">{moment(tracking.date).format('YYYY-MM-DD')}</div>
            </div>
            <div className="item-cell">
              <div className="grid-cell text">{renderTypeLabel(tracking.type)}</div>
            </div>
            <div className="item-cell">
              <div className="text color-text-light">{t('dashboard.allTrackingsComponent.nameHeader')}</div>
              <div className="grid-cell text">{tracking.user && tracking.user.name}</div>
            </div>
            <div className="item-cell">
              <div className="text color-text-light">{t('dashboard.allTrackingsComponent.statusHeader')}</div>
              <div className="grid-cell text">{renderStatus(tracking.status)}</div>
            </div>
            <div className="item-cell">
              <div className="text color-text-light">{t('dashboard.allTrackingsComponent.countryHeader')}</div>
              <div className="grid-cell text">{tracking.country && t(`settings.countryCodes.${tracking.country.name}`)}</div>
            </div>
            <div className="item-cell">
              <div className="text color-text-light">{t('dashboard.allTrackingsComponent.projectHeader')}</div>
              <div className="grid-cell text">{tracking.project && tracking.project.name}</div>
            </div>
          </div>)
        }

        <section className="table-data">
          <div className="label color-text-light">{t('dashboard.allTrackingsComponent.showing')} {trackings.length ? 1 : 0}-{Math.min(limit, trackings.length)} {t('dashboard.allTrackingsComponent.of')} {trackingsTotalCount} {t('dashboard.allTrackingsComponent.entries')}</div>
          {
            trackingsTotalCount > limit && <button className="button button-secondary ripple-effect" onClick={() => {
              getMoreTrackings()
            }}>{t('dashboard.allTrackingsComponent.showMoreButton')}</button>
          }
        </section>
      </section>

      {
        !showMobileDownloadOptions && <div className="download-report-button show-small-screens" onClick={() => {
            setShowMobileDownloadOptions(true)
            document.body.style.overflow = 'hidden'
          }}>
            <button className="button-content ripple-effect ripple-effect-lighter ripple-effect-circle">
              <img src={downloadIcon} alt="download" />
            </button>
          </div>
      }
      {
        showMobileDownloadOptions && <div className="download-modal" onClick={() => {
          setShowMobileDownloadOptions(false)
          document.body.style.overflow = 'visible'
        }}>

          <a href={`mailto:${userData.email}`} className="download-button button-mail" onClick={() => {
            console.log('MAIL')
          }}>
            <button className="button-content ripple-effect ripple-effect-circle">
              <img src={mailIcon} alt="download PDF" />
            </button>
          </a>

          <div className="download-button button-csv" onClick={handleCSVexport}>
            <button className="button-content ripple-effect ripple-effect-circle">
              <img src={csvIcon} alt="download PDF" />
            </button>
          </div>

          <div className="download-button button-pdf" onClick={handlePDFexport}>
            <button className="button-content ripple-effect ripple-effect-circle">
              <img src={pdfIcon} alt="download PDF" />
            </button>
          </div>

          <div className="close-modal-button" onClick={() => {
            setShowMobileDownloadOptions(false)
          }}>
            <button className="button-content ripple-effect ripple-effect-lighter ripple-effect-circle">
              <img src={closeIcon} alt="close" />
            </button>
          </div>
        </div>
      }

      {
        successMessaage && <Snackbar type="success" text={successMessaage} closeHandler={() => setSuccessMessage(null)} />
      }
    </div>
  );
}

export default ReportPage;
