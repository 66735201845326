import React, { useState, useEffect } from 'react';
import './Snackbar.scss';
import closeIcon from '../../../assets/close-icon-black.svg';
import classNames from 'classnames'


function Snackbar({ type, text, closeHandler }) {
  const [snackbarHidden, setSnackbarHidden] = useState(true)
  let closeModalTimeout = null
  let changeOpacityTimeout = null

  useEffect(() => {
    setSnackbarHidden(false)
    changeOpacityTimeout = setTimeout(() => setSnackbarHidden(true), 2000)
    closeModalTimeout = setTimeout(() => closeHandler(), 2500)
  }, []);

  const dismissSnackbar = () => {
    setSnackbarHidden(true)
    clearTimeout(closeModalTimeout)
    clearTimeout(changeOpacityTimeout)
    changeOpacityTimeout = setTimeout(() => closeHandler(), 500)
  }

  const snackbarContentClasses = classNames({
    'snackbar-content': true,
    'type-success': type === 'success',
    'type-warning': type === 'warning',
    'type-error': type === 'error',
  })

  return (
    <div className={`snackbar ${snackbarHidden && 'snackbar-hidden'}`}>
      <div className={snackbarContentClasses}>
        <span>{text}</span>
        <img src={closeIcon} alt="close" onClick={dismissSnackbar} />
      </div>
    </div>
  );
}

export default Snackbar;
