import React from 'react'
import './HelpPage.scss'
import { useStateValue } from '../../../state/state'
import { useTranslation } from 'react-i18next'


function HelpPage() {
  const [{ settings }] = useStateValue()
  const { t } = useTranslation()


  return (
    <div className="help-page">
      <header>
        <h1 className="hide-small-screens">{t('help.title')}</h1>
        <h3 className="show-small-screens">{t('help.title')}</h3>
      </header>
      <div className="help-content" dangerouslySetInnerHTML={{__html: settings.helpText}} />
    </div>
  )
}

export default HelpPage