import React, { useState, useEffect, useRef } from 'react'
import './PopoverMenu.scss'
import dotsIcon from '../../../assets/dots-icon.svg'
import classNames from 'classnames'


function PopoverMenu(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [menuPosition, setMenuPosition] = useState('bottom')
  const dotsElement = useRef(null)
  const menuElement = useRef(null)

  const useOutsideClick = (ref) => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsMenuOpen(false)
      }
    }
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  }
  useOutsideClick(menuElement)


  const calculateMenuPosition = () => {
    const dotsPosition = dotsElement.current && dotsElement.current.getBoundingClientRect().y
    const menuHeight = menuElement.current && menuElement.current.getBoundingClientRect().height
    const screenHeigth = window.innerHeight

    if (dotsPosition + menuHeight + 30 < screenHeigth) {
      setMenuPosition('bottom')
    } else {
      setMenuPosition('top')
    }
  }

  const hadleMenuOpen = () => {
    setIsMenuOpen(true)
    calculateMenuPosition()
    window.addEventListener('scroll', calculateMenuPosition)
    window.addEventListener('resize', calculateMenuPosition)
  }

  const hadleMenuClose = () => {
    setIsMenuOpen(false)
    window.removeEventListener('scroll', calculateMenuPosition)
    window.removeEventListener('resize', calculateMenuPosition)
  }

  const popoverMenuClasses = classNames({
    'popover-menu': true,
    'popover-menu-hidden': !isMenuOpen,
    'menu-bottom': menuPosition === 'bottom',
    'menu-top': menuPosition === 'top',
  })
  return (
    <div className="popover-menu-component">
      <div className="icon-wrapper" ref={dotsElement}>
        <img src={dotsIcon} alt="" onClick={hadleMenuOpen} />
        <div className={popoverMenuClasses} 
          ref={menuElement} 
          onClick={hadleMenuClose}
        >
          { menuPosition === 'bottom' && <div className="menu-border-triangle top-triangle" /> }
          { menuPosition === 'top' && <div className="menu-border-triangle bottom-triangle" /> }
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default PopoverMenu;
