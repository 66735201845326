import React, { useState, useEffect } from 'react';
import './NewProjectModal.scss';
import CalendarInput from '../../../common/CalendarInput/CalendarInput';
import MultichoiceSelect from '../../../common/MultichoiceSelect/MultichoiceSelect';
import Snackbar from '../../../common/Snackbar/Snackbar';
import { getCookie } from '../../../../utils'
import { default as axios } from '../../../../axiosSettings'
import { useTranslation } from 'react-i18next'


function NewProjectModal({ cancelHandler, closeAfterSuccessHandler }) {
  const [projectName, setProjectName] = useState('')
  const [projectNameError, setProjectNameError] = useState(false)
  const [endDate, setEndDate] = useState(null)
  const [endDateError, setEndDateError] = useState(false)
  const [taxCode, setTaxCode] = useState('')
  const [supervisorsOptions, setSupervisorsOptions] = useState([])
  const [supervisors, setSupervisors] = useState(null)
  const [supervisorsError, setSupervisorsError] = useState(false)
  const [validationFailedError, setValidationFailedError] = useState(null)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const token = getCookie('token')
  const { t } = useTranslation()


  useEffect(() => {
    setIsButtonDisabled(!projectName || !endDate || !supervisors)
  }, [projectName, endDate, supervisors])


  useEffect(() => {
    axios.get(`api/users/all?roles[]=ROLE_PM&roles[]=ROLE_HR`, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(response => {
        const suervisorsList = response.data.map(user => { return {value: user.id, label: user.name}})
        setSupervisorsOptions(suervisorsList)
      })
  }, [])

  const validateFormData = () => {
    const projectNameError = !projectName
    const endDateError = !endDate
    const supervisorsError = !supervisors || !supervisors.length

    if (
        !projectNameError
        && !endDateError
        && !supervisorsError
      ) {
      createNewProject()
      setValidationFailedError(null)
    } else {
      setValidationFailedError(t('newProject.validationErrorMessage'))
    }

    setProjectNameError(projectNameError)
    setEndDateError(endDateError)
    setSupervisorsError(supervisorsError)
  }
  

  const createNewProject = () => {
    const token = getCookie('token')
    axios.post(`api/projects`, {
      name: projectName,
      endDate: endDate,
      taxCode: taxCode,
      mainSupervisor: `api/users/${supervisors[0].value}`,
      supervisors: supervisors.splice(1).map(supervisor => `api/users/${supervisor.value}`),
    }, {
      headers: { Authorization: `bearer ${token}` } 
    })
      .then(() => {
        closeAfterSuccessHandler()
      })
      .catch(() => {
        setValidationFailedError(t('newProject.newProjectErrorMessage'))
      })
  }


  return (
    <div className="new-project-modal">
      <div className="modal-content">
        <h2>{t('newProject.title')}</h2>

        <div className="inputs">

          <div className="input-wrapper">
            <div className="label">{t('newProject.nameLabel')}</div>
            <input className={`text-input w-250 ${projectNameError && 'border-red'}`} type="text" placeholder={t('newProject.namePlaceholder')} value={projectName} onChange={e => setProjectName(e.target.value)} />
          </div>

          <div className="input-wrapper">
            <div className="label">{t('newProject.endDateLabel')}</div>
            <CalendarInput 
              value={endDate} 
              placeholder={t('newProject.endDatePlaceholder')} 
              changeHandler={day => setEndDate(day)} 
              error={endDateError} 
            />
          </div>

          <div className="input-wrapper">
            <div className="label">{t('newProject.taxCodeLabel')}</div>
            <input className="text-input w-250" type="text" placeholder={t('newProject.taxCodePlaceholder')} value={taxCode} onChange={e => setTaxCode(e.target.value)} />
          </div>

          <div className="input-wrapper">
            <div className="label">{t('newProject.supervisorLabel')}</div>
              <MultichoiceSelect
                placeholder={t('newProject.supervisorPlaceholder')}
                options={supervisorsOptions}
                selectedOptions={supervisors}
                changeHandler={options => setSupervisors(options)}
                width={250}
                error={supervisorsError}
              />
          </div>
        </div>

        <div className="input-wrapper buttons-wrapper">
          <button className="button button-secondary ripple-effect" onClick={cancelHandler}>{t('newProject.cancelButton')}</button>
          <button className={`button button-primary ripple-effect ripple-effect-lighter ${isButtonDisabled && 'button-primary-disabled'}`} onClick={validateFormData}>{t('newProject.createProjectButton')}</button>
        </div>
      </div>

      {
        validationFailedError && <Snackbar type="error" text={validationFailedError} closeHandler={() => setValidationFailedError(null)} />
      }
    </div>
  );
}

export default NewProjectModal
