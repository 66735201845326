import React, { useState, useEffect } from 'react'
import './EditUserModal.scss'
import SelectInput from '../../../common/SelectInput/SelectInput'
import Snackbar from '../../../common/Snackbar/Snackbar'
import { useStateValue } from '../../../../state/state'
import { validateEmail, validatePassword, getCookie } from '../../../../utils'
import { default as axios } from '../../../../axiosSettings'
import { useTranslation } from 'react-i18next'


function EditUserModal({ userData, cancelHandler, closeAfterSuccessHandler }) {
  const { t } = useTranslation()

  const statusOptions = [
    { value: 1, label: t('users.activeLabel') },
    { value: 2, label: t('users.archivedLabel') },
  ]
  const rolesOptions = [
    { value: 'ROLE_USER', label: 'User' },
    { value: 'ROLE_PM', label: 'Project manager' },
    { value: 'ROLE_HR', label: 'HR' },
  ]

  const [{ changePasswordMode }] = useStateValue()
  const [name, setName] = useState(userData.name || '')
  const [nameError, setNameError] = useState(false)
  const [employeeNumber, setEmployeeNumber] = useState(userData.employeeNumber || '')
  const [role, setRole] = useState(userData.roles && rolesOptions.find(option => option.value === userData.roles[0]))
  const [roleError, setRoleError] = useState(false)
  const [status, setStatus] = useState(userData.status ? statusOptions.find(option => option.value === userData.status) : { value: 1, label: 'Active' })
  const [email, setEmail] = useState(userData.email || '')
  const [emailError, setEmailError] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [validationFailedError, setValidationFailedError] = useState(null)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const token = getCookie('token')


  useEffect(() => {
    setIsButtonDisabled(
      name === userData.name &&
      employeeNumber === userData.employeeNumber &&
      role === userData.role &&
      status === userData.status &&
      email === userData.email &&
      password === userData.password
    )
  }, [name, employeeNumber, role, status, email, password])


  const validateFormData = () => {
    const nameError = !name
    const roleError = role === null
    const emailError = !validateEmail(email)
    const passwordError = password && !validatePassword(password)

    if (
        !nameError
        && !roleError
        && !emailError
        && !passwordError
      ) {
      saveUserChanges()
      setValidationFailedError(null)
    } else {
      setValidationFailedError(t('editUser.validationErrorMessage'))
    }

    setNameError(nameError)
    setRoleError(roleError)
    setEmailError(emailError)
    setPasswordError(passwordError)
  }

  
  const saveUserChanges = () => {
    const changes = {
      email: email,
      roles: [role.value],
      name: name,
      employeeNumber: employeeNumber,
      status: status.value,
    }

    if (password) {
      changes.password = password
    }

    axios.patch(`api/users/${userData.id}`, changes, {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/merge-patch+json',
      }
    })
      .then(() => {
        closeAfterSuccessHandler()
      })
  }


  return (
    <div className="new-user-modal">
      <div className="modal-content">
        <h2>{t('editUser.title')}</h2>

        <div className="inputs">

          <div className="input-wrapper">
            <div className="label">{t('newUser.nameLabel')}</div>
            <input className={`text-input w-250 ${nameError && 'border-red'}`} type="text" placeholder={t('newUser.namePlaceholder')} value={name} onChange={e => setName(e.target.value)} />
          </div>

          <div className="input-wrapper">
            <div className="label">{t('newUser.employeeNumberLabel')}</div>
            <input className="text-input w-250" type="text" placeholder={t('newUser.employeeNumberPlaceholder')} value={employeeNumber} onChange={e => setEmployeeNumber(e.target.value)} />
          </div>

          <div className="input-wrapper">
            <div className="label">{t('newUser.roleLabel')}</div>
            <SelectInput
              options={rolesOptions}
              selectedOption={role}
              changeHandler={option => setRole(option)}
              width='200px'
              error={roleError}
            />
          </div>

          <div className="input-wrapper">
            <div className="label">{t('editUser.statusLabel')}</div>
            <SelectInput
              options={statusOptions}
              selectedOption={status}
              changeHandler={option => setStatus(option)}
              width='200px'
            />
          </div>

          <div className="input-wrapper">
            <div className="label">{t('newUser.emailLabel')}</div>
            <input className={`text-input w-250 ${emailError && 'border-red'}`} type="text" placeholder={t('newUser.emailPlaceholder')} value={email} onChange={e => setEmail(e.target.value)} />
          </div>

          <div className="input-wrapper">
            <div className="label">{t('newUser.passwordLabel')}</div>
            <input className={`text-input w-250 ${passwordError && 'border-red'}`} type="password" autoFocus={changePasswordMode} placeholder={t('newUser.passwordPlaceholder')} value={password} onChange={e => setPassword(e.target.value)} />
            <div className="help-text">{t('newUser.passwordRequirements')}</div>         
          </div>
        </div>

        <div className="input-wrapper buttons-wrapper">
          <button className="button button-secondary ripple-effect" onClick={cancelHandler}>{t('newUser.cancelButton')}</button>
          <button className={`button button-primary ripple-effect ripple-effect-lighter ${isButtonDisabled && 'button-primary-disabled'}`} onClick={validateFormData}>{t('editUser.saveChangesButton')}</button>
        </div>
      </div>

      {
        validationFailedError && <Snackbar type="error" text={validationFailedError} closeHandler={() => setValidationFailedError(null)} />
      }

    </div>
  );
}

export default EditUserModal
