import React, { useState, useEffect, useRef } from 'react'
import './DateFilter.scss'
import dropdownArrowDown from '../../../assets/dropdown-arrow-down.svg'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment'
import moment from 'moment'
import 'moment/locale/de'
import { useTranslation } from 'react-i18next'


function DateFilter({ changeHandler }) {
  const { t, i18n } = useTranslation()

  const FILTER_NAMES = {
    'this-year': t('filters.thisYearOption'),
    'last-year': t('filters.lastYearOption'),
    'this-month':  t('filters.thisMonthOption'),
    'last-month': t('filters.lastMonthOption'),
    'this-week': t('filters.thisWeekOption'),
    'last-week': t('filters.lastWeekOption'),
    'tax-period': t('filters.taxPeriodOption'),
    'custom': t('filters.customOption'),
  }

  const [filterOpened, setFilterOpened] = useState(false)
  const [filterValue, setFilterValue] = useState('tax-period')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const buttonRef = useRef(null)
  const dropdownRef = useRef(null)

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize)
    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [filterOpened])

  const useOutsideClick = (ref) => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setFilterOpened(false)
      }
    }
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    })
  }
  useOutsideClick(dropdownRef)

  const renderFilterOption = (text, value, startDate, endDate, taxPeriodSelected) => {
    return <div className={`filter-option ${filterValue === value && 'filter-option-selected'}`} onClick={() => {
      setFilterValue(value)
      setFilterOpened(false)
      changeHandler(startDate, endDate, taxPeriodSelected)
    }}>
      {text}
    </div>
  }


  const openFilterDropdown = () => {
    setFilterOpened(true)
    const dropdownWidth = 370
    const windowWidth = document.documentElement.clientWidth || window.innerWidth
    const spaceLeft = buttonRef.current.getBoundingClientRect().x + buttonRef.current.getBoundingClientRect().width
    const spaceRight = windowWidth - buttonRef.current.getBoundingClientRect().x

    if (spaceRight >= dropdownWidth) {
      setTimeout(() => {
        document.querySelector('.filter-dropdown').style.left = 0     
        document.querySelector('.filter-dropdown').style.opacity = 1    
      }, 0)
    }
    else if (spaceLeft >= dropdownWidth) {
      setTimeout(() => {
        document.querySelector('.filter-dropdown').style.right = 0     
        document.querySelector('.filter-dropdown').style.opacity = 1   
      }, 0)
    } else {
      setTimeout(() => {
        document.querySelector('.filter-dropdown').style.left = `${(-1 * buttonRef.current.getBoundingClientRect().x + 16)}px`
        document.querySelector('.filter-dropdown').style.width = `${windowWidth - 32}px`
        document.querySelector('.filter-dropdown').style.opacity = 1
      }, 0)
    }
  }

  
  const handleWindowResize = () => {
    if (!filterOpened) return

    const dropdownWidth = 370
    const windowWidth = document.documentElement.clientWidth || window.innerWidth
    const spaceLeft = buttonRef.current.getBoundingClientRect().x + buttonRef.current.getBoundingClientRect().width
    const spaceRight = windowWidth - buttonRef.current.getBoundingClientRect().x

    if (spaceRight >= dropdownWidth) {
      document.querySelector('.filter-dropdown').style.left = 0
      document.querySelector('.filter-dropdown').style.right = 'initial'   
    }
    else if (spaceLeft >= dropdownWidth) {
      document.querySelector('.filter-dropdown').style.left = 'initial'   
      document.querySelector('.filter-dropdown').style.right = 0
    } else {
      document.querySelector('.filter-dropdown').style.left = `${(-1 * buttonRef.current.getBoundingClientRect().x + 16)}px`
      document.querySelector('.filter-dropdown').style.right = 'initial'
      document.querySelector('.filter-dropdown').style.width = `${windowWidth - 32}px`
    }
  }


  const getStartOfThisYear = () => moment().startOf('year').format('YYYY-MM-DDTHH:mm:ss')
  const getEndOfThisYear = () => moment().endOf('year').format('YYYY-MM-DDTHH:mm:ss')
  const getStartOfLastYear = () => moment().startOf('year').add(-1, 'years').format('YYYY-MM-DDTHH:mm:ss')
  const getEndOfLastYear = () => moment().endOf('year').add(-1, 'years').format('YYYY-MM-DDTHH:mm:ss')
  const getStartOfThisMonth = () => moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss')
  const getEndOfThisMonth = () => moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss')
  const getStartOfLastMonth = () => moment().startOf('month').add(-1, 'months').format('YYYY-MM-DDTHH:mm:ss')
  const getEndOfLastMonth = () => moment().endOf('month').add(-1, 'months').format('YYYY-MM-DDTHH:mm:ss')
  const getStartOfThisWeek = () => moment().startOf('isoWeek').format('YYYY-MM-DDTHH:mm:ss')
  const getEndOfThisWeek = () => moment().endOf('isoWeek').format('YYYY-MM-DDTHH:mm:ss')
  const getStartOfLastWeek = () => moment().startOf('isoWeek').add(-1, 'weeks').format('YYYY-MM-DDTHH:mm:ss')
  const getEndOfLastWeek = () => moment().endOf('isoWeek').add(-1, 'weeks').format('YYYY-MM-DDTHH:mm:ss')


  return (
    <div className="date-filter">
      
      <div className="filter-button" ref={buttonRef} onClick={openFilterDropdown}>
        <div>{FILTER_NAMES[filterValue]}</div>
        <img src={dropdownArrowDown} alt="" />
      </div>

      {
        filterOpened && <div className="filter-dropdown" ref={dropdownRef}>
          <div className="left">
            {renderFilterOption(t('filters.thisYearOption'), 'this-year', getStartOfThisYear(), getEndOfThisYear(), false)}
            {renderFilterOption(t('filters.lastYearOption'), 'last-year', getStartOfLastYear(), getEndOfLastYear(), false)}
            {renderFilterOption(t('filters.thisMonthOption'), 'this-month', getStartOfThisMonth(), getEndOfThisMonth(), false)}
            {renderFilterOption(t('filters.lastMonthOption'), 'last-month', getStartOfLastMonth(), getEndOfLastMonth(), false)}
            {renderFilterOption(t('filters.thisWeekOption'), 'this-week', getStartOfThisWeek(), getEndOfThisWeek(), false)}
            {renderFilterOption(t('filters.lastWeekOption'), 'last-week', getStartOfLastWeek(), getEndOfLastWeek(), false)}
            {renderFilterOption(t('filters.taxPeriodOption'), 'tax-period', null, null, true)}
          </div>
          <div className="right">
            <div>{t('filters.customRangeText')}</div>
            <div className="calendar-input-wrapper">
              <DayPickerInput 
                dayPickerProps={{localeUtils: MomentLocaleUtils, locale: i18n.language}}
                value={startDate && formatDate(new Date(startDate), "DD/MM/YYYY")}
                format="DD/MM/YYYY"
                selectedDay={startDate}
                formatDate={formatDate}
                parseDate={parseDate}
                placeholder={t('filters.datesFromPlaceholder')}
                onDayChange={day => setStartDate(day)} 
              />
            </div>
            <div className="calendar-input-wrapper">
              <DayPickerInput
                dayPickerProps={{localeUtils: MomentLocaleUtils, locale: i18n.language}}
                value={endDate && formatDate(new Date(endDate), "DD/MM/YYYY")}
                format="DD/MM/YYYY"
                selectedDay={endDate}
                formatDate={formatDate}
                parseDate={parseDate}
                placeholder={t('filters.datesToPlaceholder')}
                onDayChange={day => setEndDate(day)} 
              />
            </div>
            <div className="button-wrapper">
              <span className="button" onClick={() => {
                setFilterValue('custom')
                setFilterOpened(false)
                changeHandler(moment(startDate).format('YYYY-MM-DDT00:00:00'), moment(endDate).format('YYYY-MM-DDT00:00:00'))
              }}>{t('filters.applyButton')}</span>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default DateFilter;
